import { Link } from 'react-router-dom';

import React, { Component } from "react";

import '../../../css/login.css';
import { Translate } from '../../../helpers/Translate';


import { actionCreators as ParentCreator } from "../../../store/Parent";
import { connect } from "react-redux";

import { Hourglass as Loader} from "react-loader-spinner";

//import 'react-card-with-image/dist/index.css';
import Swal from 'sweetalert2';
import avatar from '../../../assets/avatar.jpeg';
const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
    GetParent: (data: any) =>
        dispatch(ParentCreator.GetParent(data)),
    ClearParent: (data: any) =>
        dispatch(ParentCreator.ClearParent(data)),

});
const mapStateToProps =(state: any)=> {
    return {
        guardian: state.Parent.guardian,
        guardianLoading: state.Parent.guardianLoading,
        error: state.Parent.error,

    };
}
const connector = connect(mapStateToProps, mapDispatchToProps);

const required = (val: string) => val && val.length;
const maxLength = (len: number) => (val: string) => !(val) || (val.length <= len);
const minLength = (len: number) => (val: string) => val && (val.length >= len);



const email = (val: string) =>
    val && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val)
        ? 'Invalid email address'
        : undefined








class ViewProfile extends Component<any, any> {
    t = new Translate();
    constructor(props: any) {
        super(props)
        this.state = {
            firstname: "",
            middlename: "",
            lastname: "",
            username: "",
            ssn: "",
            ocupation: "",
            country: "",
            mobile: "",

            countryName: null,
            picture: [],
            selectedCountry: "EGY",
            phone: "Must be Mobile Number ex: 0123456789101"
        }





        this.onDrop = this.onDrop.bind(this);
        this.onSelectCountry = this.onSelectCountry.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }


    componentDidMount() {
        if (this.props.guardian) {
            this.setState({ ...this.props.guardian })
        }


    }
    onSelectCountry(event: any) {
        this.setState({ selectedCountry: event.target.value })
    }

    handlePhone = (e: any) => {
        this.setState({
            phone: this.state.phone
        })
    }




    onDrop(picture: any) {
        this.setState({
            picture: this.state.picture.concat(picture)
        });
    }


    onCancel() {
        this.props.ClearParent()
    }

    render() {

        let error = null;
        if (this.props.error) {
            error = this.props.error
            Swal.fire({
                title:"Warning",
                html:error,
                icon: "warning"
            }).then((x) => {
                this.onCancel();
            })
        }


        return (
            !this.props.guardianLoading ? (
                <>

                    <div className="row">
                        <div id="user-profile" className="col-12">
                            <div className="profile-with-cover card">
                                <div className="card-img-top img-fluid bg-cover height-30 50% center image"

                                >
                                </div><br />
                                <div className="media profil-cover-details row">
                                    <div className="col-5">
                                        <div className="align-self-start halfway-fab pl-3 pt-2">
                                            <div className="text-left">
                                                <button type="button" className="btn btn-lg btn-primary" disabled>Profile Information </button>
                                                <h3 className="card-title2
                                               nav-link">
                                                    {this.props.guardian.firstname} &nbsp;
                                                    {this.props.guardian.middlename} &nbsp;
                                                    {this.props.guardian.lastname} &nbsp;
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="align-self-center halfway-fab text-center">
                                            <a className="profile-image">
                                                <img src={this.props.guardian.driveid ? this.props.guardian.driveid.includes("https://") ? this.props.guardian.driveid : `https://drive.google.com/uc?id=${this.props.guardian.driveid}&export=download` : `${avatar}`}
                                                    className="rounded-circle" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="media profil-cover-details row">
                                    <div className="card-body" >
                                        <Link to="/dashboard/editprofile"
                                            className="btn btn-success float-right"
                                            type="button" data-toggle="tooltip" data-placement="top" title="Edit"><i className="fa fa-edit">&nbsp;Edit Profile</i></Link>
                                        <br /><br />
                                        <table className="table table-striped">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Name:
                                                    </td>
                                                    <td>
                                                        {this.props.guardian.firstname} &nbsp;
                                                        {this.props.guardian.middlename} &nbsp;
                                                        {this.props.guardian.lastname} &nbsp;
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        National ID / SSN :
                                                    </td>
                                                    <td>
                                                        {this.props.guardian.ssn}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Email :
                                                    </td>
                                                    <td>
                                                        {this.props.guardian.username}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Occupation :
                                                    </td>
                                                    <td>
                                                        {this.props.guardian.ocupation}
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>
                                                        Country :
                                                    </td>
                                                    <td>
                                                        {this.props.guardian.country}
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>
                                                        Mobile :
                                                    </td>
                                                    <td>
                                                        {this.props.guardian.mobile}
                                                    </td>

                                                </tr>

                                                <tr>
                                                    <td>
                                                        Education Level:
                                                    </td>
                                                    <td>
                                                        {this.props.guardian.education_level}
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </>

            ) : (
                <div className="d-flex justify-content-center align-items-center mt-5">
                    <Loader height={100} width={100} />
                </div>
            )
        )
    }

}

export default connector(ViewProfile);

