

import React, { useState , useEffect } from "react";
import { useSelector } from "react-redux";
import avatar from '../../../../assets/avatar.jpeg';


const  NavItemImage =(props: any) =>{
 
    const guardian: any = useSelector(
      (state: any) => state.Parent.guardian
    );
    return (
      <li className="nav-item">
  
        <span aria-haspopup="true" style={{ cursor: "pointer" }} className="nav-item" aria-expanded="true" onClick={() => props.setopenimage()}>
          <img alt=""  src = {  guardian.driveid ? guardian.driveid?.includes("https://")?guardian.driveid : `https://drive.google.com/uc?id=${guardian.driveid}&export=download` : `${avatar}` }
           className="rounded-circle icon-button" width="40"/>      
        </span>
        {props.openimage && props.children}
       
      </li>
    );
  }
  
  export default NavItemImage