import { createSlice, createAsyncThunk, PayloadAction, configureStore } from '@reduxjs/toolkit';
import { parseJwt, requestWithAxios } from '../helpers/func';
import * as Initial from './initialStatus';

const { url } = Initial;
const initialState = Initial.Student;
/**
 * status
 *  204 => ok same data in server
 *  401 => Error unauthorized
 *  403 => Error Forbidden not loggedin
 *  500 => Error server error
 *  -1 => Error
 */


export const getStudents = createAsyncThunk('students/getStudents', async (data: any, thunkAPI) => {
  const res = await requestWithAxios(url + 'student/getstudents.php', data);
  return res.data.data;
});

export const getExcusesReasons = createAsyncThunk('students/getExcusesReasons', async (_, thunkAPI) => {
  const res = await requestWithAxios(url + 'student/excuse/getexcusereasons.php', {});
  return res.data.data;
});

export const addExcuse = createAsyncThunk('students/addExcuse', async (data: any, thunkAPI) => {
  const res = await requestWithAxios(url + 'student/excuse/addexcuse.php', data);
  console.log(res);
});

const studentSlice = createSlice({
  name: 'students',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStudents.fulfilled, (state, action: PayloadAction<any[]>) => {
        state.students = action.payload;
      })
      .addCase(getExcusesReasons.fulfilled, (state, action: PayloadAction<any[]>) => {
        state.ExcusesReasons = action.payload;
      });
  },
});

export const actionCreators={
  GetStudents:getStudents,
  GetExcusesReasons:getExcusesReasons,
  AddExcuse:addExcuse
}

export default studentSlice.reducer;
