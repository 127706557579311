import React from 'react';


function GridItem({ ...props }: any) {
 
  const { classes, children, ...rest } = props;
  return (
    <div item={true} {...rest} className={["col"]}>
      {children}
    </div>
  );
}

export default GridItem;
