import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Request } from '../helpers/func';
import { md5 } from 'js-md5';
import * as Initial from './initialStatus';

export interface Page {
    msg: string;
    Table: [] | null | undefined;
}

export interface ComponentPage {
    state: any | undefined;
}

interface RequestPayload {
    path: string;
    method: string;
    pageName: string;
    data: any;
}

// Async thunks
export const getTable:any = createAsyncThunk(
    'componentCreator/getTable',
    async ({ path, method, pageName, data }:RequestPayload, { getState, rejectWithValue }) => {
        try {
            const state:any = getState();
            if (state && state.componentCreator && state.user) {
                let table = [];
                let hash = md5(" ");
                if (state.componentCreator[pageName]) {
                    table = state.componentCreator[pageName].Table || [];
                    if (table.length) hash = md5(JSON.stringify(table));
                }
                const res = await Request(Initial.url + path, method, hash, state.user.token);
                const status = res.status;
                const jdata = await res.json();
                if (status === 200) {
                    return { pageName, data: { Table: jdata, msg: "", isLoading: false, gotData: true } };
                } else if (status === 403) {
                    return { type: 'CLEAR_USER_LOGOUT', user: Initial.UserState };
                } else if (status !== 208) {
                    throw new Error();
                }
            } else {
                throw new Error('state not defined');
            }
        } catch (error) {
            return rejectWithValue({ pageName, data: { msg: "Error", isLoading: false, gotData: true } });
        }
    }
);

export const addItem:any = createAsyncThunk(
    'componentCreator/addItem',
    async ({ path, method, pageName, data }:RequestPayload, { getState, rejectWithValue }) => {
        try {
            const state:any = getState();
            if (state && state.componentCreator && state.user) {
                let table = [...(state.componentCreator[pageName]?.Table || [])];
                const res = await Request(Initial.url + path, method, data, state.user.token);
                const status = res.status;
                const jdata = await res.json();
                if (status === 200) {
                    table.push(jdata.Result);
                    return { pageName, data: { Table: table, msg: "" } };
                } else {
                    throw new Error();
                }
            } else {
                throw new Error('state not defined');
            }
        } catch (error) {
            return rejectWithValue({ pageName, data: { msg: "Error" } });
        }
    }
);

export const editItem:any = createAsyncThunk(
    'componentCreator/editItem',
    async ({ path, method, pageName, data }:RequestPayload, { getState, rejectWithValue }) => {
        try {
            const state:any = getState();
            if (state && state.componentCreator && state.user) {
                let table = [...(state.componentCreator[pageName]?.Table || [])];
                const res = await Request(Initial.url + path, method, data, state.user.token);
                const status = res.status;
                const jdata = await res.json();
                if (status === 200) {
                    const itemId = data[Object.keys(data)[0]];
                    const itemIndex = table.findIndex(x => x[Object.keys(x)[0]] === itemId);
                    if (itemIndex > -1) {
                        table[itemIndex] = data;
                    }
                    return { pageName, data: { Table: table, msg: "" } };
                } else {
                    throw new Error();
                }
            } else {
                throw new Error('state not defined');
            }
        } catch (error) {
            return rejectWithValue({ pageName, data: { msg: "Error" } });
        }
    }
);

export const deleteItem:any = createAsyncThunk(
    'componentCreator/deleteItem',
    async ({ path, method, pageName, data }:RequestPayload, { getState, rejectWithValue }) => {
        try {
            const state:any = getState();
            if (state && state.componentCreator && state.user) {
                let table = [...(state.componentCreator[pageName]?.Table || [])];
                const res = await Request(Initial.url + path, method, data, state.user.token);
                const status = res.status;
                const jdata = await res.json();
                if (status === 200) {
                    const itemIndex = table.findIndex(x => x[Object.keys(x)[0]] === data);
                    if (itemIndex > -1) {
                        table.splice(itemIndex, 1);
                    }
                    return { pageName, data: { Table: table, msg: "" } };
                } else {
                    throw new Error();
                }
            } else {
                throw new Error('state not defined');
            }
        } catch (error) {
            return rejectWithValue({ pageName, data: { msg: "Error" } });
        }
    }
);

// Slice
const componentCreatorSlice = createSlice({
    name: 'componentCreator',
    initialState: {},
    reducers: {
        clearUserLogout: (state:any) => {
            state.user = Initial.UserState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTable.fulfilled, (state, action) => {
                state[action.payload.pageName] = action.payload.data;
            })
            .addCase(getTable.rejected, (state, action) => {
                state[action.payload.pageName] = action.payload.data;
            })
            .addCase(addItem.fulfilled, (state, action) => {
                state[action.payload.pageName] = action.payload.data;
            })
            .addCase(addItem.rejected, (state, action:any) => {
                state[action.payload.pageName] = action.payload.data;
            })
            .addCase(editItem.fulfilled, (state, action) => {
                state[action.payload.pageName] = action.payload.data;
            })
            .addCase(editItem.rejected, (state, action:any) => {
                state[action.payload.pageName] = action.payload.data;
            })
            .addCase(deleteItem.fulfilled, (state, action) => {
                state[action.payload.pageName] = action.payload.data;
            })
            .addCase(deleteItem.rejected, (state, action:any) => {
                state[action.payload.pageName] = action.payload.data;
            });
    }
});

export const { clearUserLogout } = componentCreatorSlice.actions;

export const actionCreators={
    AddItem:addItem,
    GetTable:getTable,
    DeleteItem:deleteItem,
    EditItem:editItem,
}

export default componentCreatorSlice.reducer;