import axios from "axios";
import { url } from "../store/initialStatus";

function isArabic(text: string) {
    const arabicRegex = /[\u0600-\u06FF]/;
    return arabicRegex.test(text);
}

function wordFilter(word: any) {
    return ((word) && ('string' === typeof word) && (isNaN((word as any))) && (!isArabic(word)));
}


export class Translate {
    static lang = "EN";
    static storedDictionary: any = [];
    static pending_keyword: any = [];
    static preferred_language = "en";
    static _hasRun = false;
    static _w: any = window;
    static _isAuth: boolean = Translate._w._isAuth ?? false;

    constructor() {
        // why use a function here instead of a code ? because we use it in app.js
        // I commented this out because we onlt get the dictionary after the user has logged in
        //this.getDictionary()
    }

    getDictionary() {
        try {

            let localDistionary = localStorage.getItem("dictionary");
            if (localDistionary) {
                Translate._hasRun = true;
                Translate.storedDictionary = JSON.parse(localDistionary);
            } else {
                if (!Translate._hasRun) {
                    Translate._hasRun = true;
                    axios.get(url + "/translation/getdictionary.php").then((res) => {
                        Translate.storedDictionary = res?.data?.data?.data
                        localStorage.setItem("dictionary", JSON.stringify(Translate.storedDictionary));
                        //window.location.reload();
                    }).catch((e)=>{
                        console.log(e)
                    });
                }
            }
            let stored_preferred_lang = localStorage.getItem("preferred_lang");
            if (!stored_preferred_lang) {
                Translate.preferred_language = "en";
            } else {
                Translate.preferred_language = stored_preferred_lang;
            }
        } catch (error) {
            Translate.storedDictionary = [];
        }
        return true;

    }
    translateAuto() {

        setTimeout(() => {
            window.requestAnimationFrame(() => {
                // let allelements = document.querySelectorAll('*')
                let allelements = document.getElementsByTagName('a')
                for (let i = 0, max = allelements.length; i < max; i++) {
                    // Do something with the element here
                    let element = allelements[i]
                    let index = Translate.storedDictionary.findIndex((w: any) => w.word === element.textContent)
                    console.log(element.textContent, 'auto auto');
                    if (index !== -1 && Translate.preferred_language === "en") {
                        element.textContent = Translate.storedDictionary[index].EN
                    } else if (index !== -1 && Translate.preferred_language === "ar") {
                        element.textContent = Translate.storedDictionary[index].AR
                    } else {
                        return element.textContent;
                    }
                }


            })
        })
    }

    
    static replaceSpecificText(node:any,targetSentence:string,replacementSentence:string) {
      // Check if the node is a Text node
      if (node.nodeType === Node.TEXT_NODE) {
        // Check if the node contains the target sentence
        if (node.nodeValue.includes(targetSentence)) {
          // Replace the target sentence with the replacement sentence
          node.nodeValue = node.nodeValue.replace(targetSentence, replacementSentence);
        }
      } else {
        // Recursively process child nodes
        for (let i = 0; i < node.childNodes.length; i++) {
          Translate.replaceSpecificText(node.childNodes[i],targetSentence,replacementSentence);
        }
      }
    }
    
    // Start the replacement process from the body of the document

    translate(word: any) {
        try {
            if (wordFilter(word) && Translate._hasRun && Translate.storedDictionary) {
                word = word?.trim() ?? '';
                let index = Translate.storedDictionary.findIndex((w: any) => w.keyword?.toLowerCase() === word?.toLowerCase());
                if (index !== -1 && Translate.preferred_language === "en") {
                    return Translate.storedDictionary[index].en;
                } else if (index !== -1 && Translate.preferred_language === "ar") {
                    return Translate.storedDictionary[index].ar;
                } else {
                    if(Translate.pending_keyword.find((f:string)=> f==word.toLowerCase()) === undefined){
                        Translate.pending_keyword.push(word.toLowerCase());
                        axios.post(url + "/translation/addword.php", { keyword: word })
                            ?.then((res: any) => {
                                const res_data_data = res?.data?.data?.data;
                                if (res_data_data) {
                                    Translate.storedDictionary = [
                                        ...Translate.storedDictionary,
                                        res_data_data,
                                    ];
                                    Translate.replaceSpecificText(document.body,word,res_data_data[Translate.preferred_language])
                                    localStorage.setItem("dictionary", JSON.stringify(Translate.storedDictionary));
                                }
                            })
                            ?.catch((e: any) => {
                                console.log("Error", e);
                             });
                    }
                }
            }
        } catch (error) {
        }
        return word;
    }
}