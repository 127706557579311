import React,{Component} from 'react';
import {Label} from 'reactstrap';
//import CreatableSelect from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import { Request,LoadFromStore,ListOfObj2SelectOptions, isNullOrUndefined }from '../../helpers/func';
import * as Initial from '../../store/initialStatus';
import {md5} from 'js-md5';
//import { OptionTypeBase } from 'react-select';
import moment from 'moment';
import '../../css/datetimePicker.css';
//import Control from 'react-select/src/components/Control';

function Div(props:any){
    if(props.condition)
    return(
        <div className={props.className}>
            {props.children}
        </div>
    );
    else{
        return(
            <React.Fragment>
                {props.children}
            </React.Fragment>
        )
    }
    
}
function MyLabel(props:any){
    if(props.condition)
    return(
        <Label check={props.check} className={props.className}>
            {props.children}
        </Label>
    );else{
        return(
            <React.Fragment>
                {props.children}
            </React.Fragment>
        );
    }
    
}
interface InputProps{
    name: string,
    type: string,
    isMulti?: boolean,
    options?: Array<any>
    onChange?: any,
    validators?: any,
    messages?: any,
    value?: any,
    placeholder?: string,
    creatable?: boolean,
    label?: string,
    className?:string
}
export class Input extends Component<InputProps>{
    input: React.RefObject<HTMLInputElement>;
    feedback: React.RefObject<HTMLDivElement>;
    creatableRef: any;
    Options: Array<any> = [];
    readonly state:any;
    constructor(props:InputProps){
        super(props);
        this.state={
            err:"",
            value:this.props.value,
            name:this.props.name,
            touched:false  ,
            moment: moment()         
        }
        const op = (this.props.options === undefined)? []: this.props.options;
        if(!isNullOrUndefined(this.props.options) && op.length >= 0 ){
            this.Options = ListOfObj2SelectOptions(op);
        }
        this.input = React.createRef();
        this.feedback = React.createRef();
        this.handelBlur = this.handelBlur.bind(this);
        this.handelChange = this.handelChange.bind(this);
        this.getValue = this.getValue.bind(this);
    }
    handelBlur(event:any){
        this.setState({
            ...this.state,touched:true,value:event.currentTarget.value,err:this.validate(event.currentTarget.value)
        })
    }
    handelChange(event: any) {
        //  
        if(this.state.touched){
            this.setState({
                ...this.state,err:this.validate(event.currentTarget.value)//,value:event.currentTarget.value
            });
            //  
        } 
        if(this.props.onChange != null)this.props.onChange(event.currentTarget.value);
        
    }
    handleDateChange = (moment: any) => {
        this.setState({
          moment
        });
      }
    validate(value:any){
        let err = false;
        let msg="";
        for(var x in this.props.validators){
            if(this.props.messages == null)break;
            if(!this.props.validators[x](value)){
                err = true;

                //  
                if (this.input.current !== null && this.input.current.classList.contains('is-valid')) {
                    this.input.current.classList.remove("is-valid");
                }
                if (this.input.current != null && !this.input.current.classList.contains("is-invalid")) {
                    this.input.current.classList.add("is-invalid")
                }
                if(this.feedback.current != null){
                    if(this.feedback.current.classList.contains('valid-feedback')){
                        this.feedback.current.classList.remove("valid-feedback");
                    }
                    if(!this.feedback.current.classList.contains("invalid-feedback")){
                        this.feedback.current.classList.add("invalid-feedback");
                    }
                }

               msg=this.props.messages[x];
            }
        }
        if(!err){
            if (this.input.current!== null && this.input.current.classList.contains('is-invalid')){
                this.input.current.classList.remove("is-invalid");
            }
            if (this.input.current !== null &&!this.input.current.classList.contains("is-valid")){
                this.input.current.classList.add("is-valid");
            }
            if(this.feedback.current !=null){
                if(this.feedback.current.classList.contains('invalid-feedback')){
                    this.feedback.current.classList.remove("invalid-feedback");
                }
                if(!this.feedback.current.classList.contains("valid-feedback")){
                    this.feedback.current.classList.add("valid-feedback");
                }
            }

            msg="Looks good!"
        }
        return msg;
    }
    getValue(){
        return({name:this.state.name,value:this.state.value});
    }
    filter = (inputValue: string,options:any) => {
        return options.filter((i: any) =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };
    handleCreatableChange = (newValue: any, actionMeta: any) => {
        /* console.group('Value Changed');
          
          ${actionMeta.action}`);
        console.groupEnd(); */
        if(actionMeta.action === 'create-option'){
            // create option

        }
      };
    getDateTime(){
        try {
            return this.state.moment.format('YYYY-MM-DD HH:mm:ss');
        } catch (error) {
            return "";
        }
    }
    promiseOptions = async (inputValue: any) =>{
        
            // needed to be optemized
            let appState = LoadFromStore('state');
            if(this.Options.length === 0){
                let url = Initial.url + '/Get'+this.props.name.substring(0,this.props.name.length-2)+'s';
                //  0,this.props.name.length-2));"{\"sign\":\"99a9762b5cb15e1c0564cc59a97063ae\"}"
                await Request(url, "POST", {sign:md5(JSON.stringify(this.Options))}, appState.user.token)
                .then((res) => {
                    let status = res.status;
                    let jdata = res.json;
                    //  
                    if (status === 200) {
                        // New Data or data changed
                        this.Options = ListOfObj2SelectOptions(jdata);
                        return(this.filter(inputValue , this.Options))
                    } else {
                        return([])
                    }
                }).catch((err: any) => {
                      
                    return ([])
                    //dispatch({ type: Error_USERS,msg:"Error",Users:Us});
                });
            }else{
                return(this.filter(inputValue , this.Options));
            }
    };
    render(){
        let inp = <Div className="col-12 col-sm-10" condition ={this.props.type !== 'checkbox'} >
                    <input ref={this.input} type={this.props.type} className={this.props.type === 'checkbox' ? "form-check-input" : "form-control "}
                        id={this.props.name} name={this.props.name}
                        defaultValue={this.props.value}
                        placeholder={this.props.placeholder}
                        {...this.props.type === 'password' ? {autoComplete:"current-password"}:{autoComplete:this.props.name}}
                        onBlur={this.handelBlur}
                        onChange={this.handelChange}
                        required={this.props.validators != null && this.props.validators.required === true}
                    />
                    {this.props.type !=="checkbox"?<div ref={this.feedback} className="text-left">
                        {this.state.err}
                    </div>:null}
                </Div>
        if(this.props.type ==='select'){
            if(this.props.creatable){
                inp =   <div className="col-12 col-sm-10">
                           <AsyncCreatableSelect
                                ref={ref => {
                                    this.creatableRef = ref;
                                  }}
                                cacheOptions
                                defaultOptions
                                onChange={this.handleCreatableChange}
                                loadOptions={this.promiseOptions}
                            />
                        </div>
            }else{
                //let x:OptionTypeBase = {key:1};
                inp = <div className="col-12 col-sm-10">
                        <AsyncSelect 
                            cacheOptions
                            defaultOptions
                            isMulti={this.props.isMulti}                          
                            onChange={this.props.onChange}
                            loadOptions={this.promiseOptions} 
                            id = {this.props.name}
                            name = {this.props.name}
                            defaultValue={this.props.value}
                            className={this.props.isMulti?"basic-multi-select":""}
                            styles={{control: styles => ({ ...styles, backgroundColor: 'white', color:"red" }),
                                    option:styles =>({...styles,color:"rgb(34, 37, 41)"})}}
                            />
                        </div>
            }
        }else if(this.props.type.search("datetime") !== -1){
            /* let DatetimePickerTrigger = require('rc-datetime-picker').DatetimePickerTrigger;
            const shortcuts = {
                'Today': moment(),
                'Yesterday': moment().subtract(1, 'days'),
                'Clear': ''
              };
            //  
            inp = <Div className="col-12 col-sm-10" condition ={this.props.type !== 'checkbox'} >
                    <DatetimePickerTrigger
                        shortcuts={shortcuts} 
                        moment={this.state.moment}
                        onChange={this.handleDateChange}>
                        <input type="text" 
                            className="form-control "
                            id={this.props.name} name={this.props.name}
                            
                            placeholder={this.props.placeholder}
                            value={this.getDateTime()} readOnly />
                    </DatetimePickerTrigger>
            {this.props.type !=="checkbox"?<div ref={this.feedback} className="text-left">
                {this.state.err}
            </div>:null}
        </Div> */
        }
        return(
            <div className="row form-group">
                <Div condition={this.props.type==='checkbox'} className="col-xs-offset-0 offset-sm-2 col-xs-12 col-10">
                    <Div condition={this.props.type==='checkbox'} className="form-check text-left">
                       <MyLabel condition={this.props.type==='checkbox'} check={true}>
                            {(this.props.type !== 'checkbox' && this.props.type !== 'hidden')?<Label htmlFor={this.props.name} className="col-2">{this.props.label}</Label>:null}
                                {inp}
                            {this.props.type === 'checkbox'?<strong>{this.props.label}</strong>:null}
                        </MyLabel>
                    </Div>
                </Div>
                
               </div>

        );
    }

}

export class Form extends Component<any>{
    form: React.RefObject<HTMLFormElement>;
    constructor(props:any){
        super(props);
        this.form = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);                         
    }
    handleSubmit(event:any){
        event.preventDefault();
        let result: { [k: string]: any } = {};
        //  
        if(this.form.current !== null)
        for (let ele in this.form.current.getElementsByTagName('input')) {
            let element = this.form.current.getElementsByTagName('input')[ele] as unknown as HTMLInputElement;
            if (element !== null && element !== undefined){
                //  {name:ele.name,value:ele.type=="checkbox"?ele.checked:ele.value})
                result[element.name] = (element.type === "checkbox" ? element.checked : element.value);
            
            }
            
        }
        //  
        this.props.onSubmit(result);
        
    }
    render(){
        return(
            <form ref={this.form} onSubmit={this.handleSubmit}>
                
                {this.props.children}
            </form>
        );
    }
}