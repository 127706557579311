import { ReactComponent as BellIcon } from "./icons/bell.svg";
import { ReactComponent as MessengerIcon } from "./icons/messenger.svg";
import React, { useState, useEffect } from "react";


import {  useSelector } from "react-redux";

import NavItem from "./navmenu/NavItem";
import NavItemMsng from "./navmenu/NavItemMsng";
import NavItemImage from "./navmenu/NavItemImage";
import DropdownMenu from "./navmenu/DropdownMenu";
import NotifyDropdownMenu from "./navmenu/NotifyDropdownMenu";
import MsngDropdownMenu from "./navmenu/MsngDropdownMenu";

import Select from "react-select";
import { Translate } from "../../../helpers/Translate";

function ParentMenu() {
  //const dispatch: Dispatch<any> = useDispatch();
  let t = new Translate();

  //const [token,Settoken] = useState('');
  const [Refresh, setRefresh] = useState(false);
  useEffect(() => {}, []);
  const [openimage, setOpenimage] = useState(false);
  const [opennoti, setOpennoti] = useState(false);
  const [openmsg, setOpenmsg] = useState(false);
  const setopenimage = () => {
    setOpenimage(!openimage);
    setOpennoti(false);
    setOpenmsg(false);
  };
  const setopennoti = () => {
    setOpennoti(!opennoti);
    setOpenmsg(false);
    setOpenimage(false);
  };
  const setopenmsg = () => {
    setOpenmsg(!openmsg);
    setOpennoti(false);
    setOpenimage(false);
  };

  const options = [
    { value: "en", label: "EN" },
    { value: "ar", label: "AR عربي" },
  ];
  const [selectedOption, setSelectedOption] = useState({
    value: "en",
    label: "EN",
  });

  let preferred_lang = localStorage.getItem("preferred_lang");
  let defauleLang = { value: "en", label: "EN" };
  if (!preferred_lang) {
    localStorage.setItem("preferred_lang", "en");
    defauleLang = { value: "en", label: "EN" };
  } else {
    if (preferred_lang === "en") {
      defauleLang = { value: "en", label: "EN" };
    } else if (preferred_lang === "ar") {
      defauleLang = { value: "ar", label: "AR عربي" };
    }
  }

  const openticket = () => {
    window.open(`ticket.ivyis.org/backend/passtoken.php?hash=${jwt}`);
  };
  let jwt = localStorage.getItem("token");
  const changeLanguage = (e: any) => {
    // console.log(e);
    localStorage.setItem("preferred_lang", e.value);
    window.location.reload();
  };
  return (
    <Navbar>
      <button
        style={{
          backgroundColor: "smokywhite",
          borderRadius: "5px",
          margin: "1%",
          fontSize: "15px",
          minWidth: "60px",
        }}
      >
        <a target="_blank" href={process.env.REACT_APP_TURL}>
          {t.translate("Ticket")}
        </a>
      </button>
      <div style={{ minWidth: "90px", margin: "3%" }}>
        <Select
          defaultValue={defauleLang}
          onChange={(e: any) => changeLanguage(e)}
          options={options}
        />
      </div>

   

      {/* <a href={`ticket.ivyis.org/backend/passtoken.php?hash=${jwt}`}>
        <Button onClick={openticket}  style={{backgroundColor:"#dadce1",color:"#03589b",marginTop:"5%"}}> Add A New Ticket</Button>
     </a> */}

     
      <NavItemImage openimage={openimage} setopenimage={setopenimage}>
        <DropdownMenu></DropdownMenu>
      </NavItemImage>
    </Navbar>
  );
}

function Navbar(props: any) {
  return (
    <nav className="navbar-parent">
      <ul className="navbar-nav-parent">{props.children}</ul>
    </nav>
  );
}

export default ParentMenu;
