import React, { useEffect, useState } from "react";
import {  Card, CardBody, CardHeader } from "reactstrap";
import Swal from "sweetalert2";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { formatDateTime as format } from "../../../helpers/func";
import axios from "axios";
import Timeline from "./Timeline";
import { useParams } from "react-router-dom";
import { Translate } from "../../../helpers/Translate";
interface IFormData {
  id?: string;
  parent?: string;
  students?: Array<string>;
  reason?: string;
  attachments: Array<any>;
  student_id?: string;
  parentSign: string;
  datetime: string;
  relativename: string;
  nationalId: string;
}

const EarlyDismissal: React.FC = () => {
  let t = new Translate();
  const { id } = useParams<{ id?: string }>();

  const [modal, setModal] = useState(false);
  const [view, setView] = useState(false);
  const [lastEnabledDate, setLastEnabledDate] = useState("");
  const [Moods, setMoods] = useState<any>([]);
  const [enabledDates, setEnabledDates] = useState<any>([]);
  const [activities, setActivities] = useState<any>([]);
  const [entryAndLeaveTime, setEntryAndLeaveTime] = useState<any>([]);
  const [img, setImg] = useState(" ");
  const [formData, setFormData] = useState<IFormData>({
    id: "0",
    parent: "",
    students: [],
    attachments: [],
    reason: "",
    parentSign: "",
    datetime: "",
    relativename: "",
    nationalId: "",
  });

  const handleOpen = () => {
    setFormData((prevstate: any) => ({
      ...prevstate,
      id: "0",
      parent: "",
      students: [],
      attachments: [],
      reason: "",
      parentSign: "",
    }));
    setModal(true);
  };
  useEffect(() => {
    try {
      Swal.showLoading();
      if (formData.datetime !== "") {
        axios
          .post(
            `${process.env.REACT_APP_BASEURL}student/getclassactivities.php`,
            { student_id: id, date: formData.datetime }
          )
          .then((res) => {
            setActivities(res.data.data?.activities);
            setEnabledDates(res.data.data?.enableddates);
            setEntryAndLeaveTime(res.data.data?.arrivalandleave);
            setLastEnabledDate(res.data.data?.lastenableddate);
            setMoods(res.data.data?.moods);
            Swal.close();
          });
      } else {
        axios
          .post(
            `${process.env.REACT_APP_BASEURL}student/getclassactivities.php`,
            { student_id: id, date: "" }
          )
          .then((res) => {
            setEnabledDates(res.data.data?.enableddates);
            setMoods(res.data.data?.moods);
            setLastEnabledDate(res.data.data?.lastenableddate);
            setFormData((prevState) => ({
              ...prevState,
              datetime: res.data.data?.lastenableddate,
            }));
            Swal.close();
          });
      }
    } catch {
      Swal.fire("Error while grapping interventions!", "", "error")
        .then(() => {
          setFormData((prevstate: any) => ({
            ...prevstate,
            id: "0",
            parent: "",
            students: [],
            attachments: [],
            reason: "",
            parentSign: "",
          }));
        })
        .finally(() => {
          setModal(false);
        });
    }
  }, [formData?.datetime]);

  var initialValueDate = new Date(lastEnabledDate);
  initialValueDate.setHours(10);
  initialValueDate.setMinutes(0);
  initialValueDate.setMilliseconds(0);

  const disablePastDt = (current: any) => {
    if (
      enabledDates?.find(
        (date: any) => date.date === format(current._d, "yyyy-MM-dd")
      )
    ) {
      return (current._isValid = true);
    }
  };

  const clearState = () => {
    setFormData({
      id: "0",
      parent: "",
      students: [],
      reason: "",
      attachments: [],
      student_id: "",
      parentSign: "",
      datetime: "",
      relativename: "",
      nationalId: "",
    });
  };

  const handleChange = (e: any) => {
    var name: any = "datetime";
    var value: any = "";

    if (e._isAMomentObject || e.target === null || e.target === undefined) {
      if (e._isAMomentObject) {
        value = e.format();
      } else {
        value = new Date().toISOString();
      }
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };
  return (
    <>
      <div className="row justify-content-center w-100 mx-0 my-5 linkssec">
        <Card>
          <CardHeader>{t.translate("Daily Activities")}</CardHeader>
          <hr />
          <div style={{ width: "100%" }}>
            <label>{t.translate("Desired Date")}</label>
            {enabledDates.length > 0 ? (
              <Datetime
                timeConstraints={{
                  hours: { min: 8, max: 16, step: 1 },
                  minutes: { min: 0, max: 59, step: 1 },
                  seconds: { min: 0, max: 59, step: 1 },
                }}
                value={initialValueDate}
                //isValidDate={disablePastDt}
                utc
                dateFormat="MMMM Do YYYY"
                onChange={(e: any) => handleChange(e)}
              />
            ) : null}
          </div>
          <CardBody className="bg-light">
            <Timeline
              activities={activities}
              entryandleavetime={entryAndLeaveTime}
              moods={Moods}
            />
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default EarlyDismissal;
