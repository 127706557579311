import React, { useState, useEffect } from "react";
import { Hourglass as Loader } from "react-loader-spinner";
import ivy_logo from '../../../assets/ivy_logo.png';
import harmony_logo from '../../../assets/harmonylogo.svg';
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { actionCreators as ParentCreator } from "../../../store/Parent";

const ChangePass: React.FC<any> = (props) => {
    const Navigate = useNavigate();
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const jwt = localStorage.token;

    const dispatch = useDispatch();
    const updateGuardianLoading = useSelector((state: any) => state.Parent.updateGuardianLoading);
    const updateMsg = useSelector((state: any) => state.Parent.updateMsg);
    const error = useSelector((state: any) => state.Parent.error);

    const ChangePassword = (data: any) => dispatch(ParentCreator.ChangePassword(data));
    const ClearParent = (data: any) => dispatch(ParentCreator.ClearParent(data));

    const onSubmit = (e: any) => {
        e.preventDefault();
        ChangePassword({ currentpassword: currentPassword, newpassword: newPassword, confirmnewpassword: confirmNewPassword, jwt });
    };

    const onCancel = () => {
        ClearParent({});
        Navigate("/login");
    };

    useEffect(() => {
        if (error) {
            Swal.fire({
                title: "warning",
                text: error,
                icon: "warning"
            }).then(() => {
                onCancel();
            });
        }
    }, [error]);

    useEffect(() => {
        if (updateMsg) {
            Swal.fire({
                title: "Success",
                icon: "success",
                text: updateMsg
            }).then(() => {
                onCancel();
            });
        }
    }, [updateMsg]);

    return (
        <div className='container '>
            <div
                className='row align-items-center justify-content-center'
                style={{ height: '100vh' }}
            >
                {!updateGuardianLoading ? (
                    <div className='col-xl-10 col-lg-12 col-md-9'>
                        <div className='card o-hidden border-0 shadow-lg '>
                            <div className='card-body p-0'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <div className='p-5 bg-ivy-dark'>
                                            <div className='col-12 row justify-content-center align-items-center align-content-center '>
                                                <div className='col-12 row justify-content-center align-items-center mt-3'>
                                                    <img
                                                        src={ivy_logo}
                                                        height='150px'
                                                        width='150px'
                                                        className='rounded d-block '
                                                        style={{ width: 'auto' }}
                                                        alt='...'
                                                    />
                                                </div>
                                                <div className='col-12 row justify-content-center align-items-center mt-3'>
                                                    <img
                                                        src={harmony_logo}
                                                        height='40px'
                                                        className='rounded d-block'
                                                        style={{ width: 'auto' }}
                                                        alt='...'
                                                    />
                                                </div>
                                                <div className='col-12 mt-4  text-center'>
                                                    <h2
                                                        className='text-white'
                                                        style={{
                                                            textShadow:
                                                                '0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)',
                                                            fontSize: '1.2rem',
                                                        }}
                                                    >
                                                    </h2>
                                                </div>
                                            </div>

                                            <form className='col-12' onSubmit={onSubmit}>
                                                <div className='form-group row  justify-content-center mt-2'>
                                                    <div className='col-md-6 col-xl-5'>
                                                        <input
                                                            id='changepass'
                                                            value={currentPassword}
                                                            onChange={(e) => { setCurrentPassword(e.target.value) }}
                                                            required={true}
                                                            maxLength={100}
                                                            type="text"
                                                            className='form-control form-control-lg border-0 text-black'
                                                            placeholder='Current Password'
                                                        />
                                                        <br />
                                                        <input
                                                            id='newpass'
                                                            value={newPassword}
                                                            onChange={(e) => { setNewPassword(e.target.value) }}
                                                            required={true}
                                                            maxLength={100}
                                                            type="text"
                                                            className='form-control form-control-lg border-0 text-black'
                                                            placeholder='New Password'
                                                        />
                                                        <br />
                                                        <input
                                                            id='newpass2'
                                                            value={confirmNewPassword}
                                                            onChange={(e) => { setConfirmNewPassword(e.target.value) }}
                                                            required={true}
                                                            maxLength={100}
                                                            type="text"
                                                            className='form-control form-control-lg border-0 text-black'
                                                            placeholder='New Password'
                                                        />
                                                    </div>
                                                </div>
                                                <div className='form-group row  justify-content-center mt-5'>
                                                    <div className='col-md-6 col-xl-5'>
                                                        <button
                                                            type='submit'
                                                            className={`btn btn-secondary  btn-block btn-warning text-white `}
                                                        >
                                                            Next
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='d-flex justify-content-center align-items-center mt-5'>
                        <Loader height={100} width={100} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ChangePass;