import React, { useState } from 'react';

import { url } from '../../../../store/initialStatus';
import SignatureCanvas from 'react-signature-canvas'

import { Row, Col, ModalBody, ModalFooter, Modal, ModalHeader, Form, Input, FormGroup } from 'reactstrap';

import "./Home.css";
import Swal from 'sweetalert2';
import axios from 'axios';
import { useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


interface formData {
  confirm: boolean;
  Signature: string
}

const Chromebook = () => {


  const [modal, setModal] = useState(false);

  const [state, setState] = useState({ trimmedDataURL: null,confirm:true });

  var sigPad: any = {};

  const clear = () => {
    sigPad.clear()
  }

  const trim = () => {
    setState({...state,
      trimmedDataURL: sigPad.getTrimmedCanvas().toDataURL('image/png')
    })
  }
  const clearState = () => {
    setModal(false);
  }

  const getStudent = () => {
    axios.post(`${url}guardian/getchromebook.php`).then((res: any) => {
      if (res.data.data.didbefore == "No") {
        setModal(true);
      } else {

      }
    })
  }
  useEffect(() => {
    (async () => {
      getStudent()
    })();
  }, []);


  const submitHandler = (e: any) => {
    e.preventDefault();
    Swal.showLoading();
    var d = sigPad.getTrimmedCanvas().toDataURL('image/png');
    state.trimmedDataURL = d;
      axios.post(`${url}guardian/savechromebook.php`, state).then((res: any) => {
      if (res.data.status === "success") {
        Swal.fire("Saved Successfully!", "", "success").then(() => {
          clearState()
          getStudent()
        })
      } else {
        Swal.fire("Something go wrong please try again later !!", "", "error");
      }
    })
  }


  return (
    <Modal isOpen={modal}>
      <ModalHeader>Acknowledgment of the student's parent</ModalHeader>
      <ModalBody>
        <Form onSubmit={submitHandler}>
          <Tabs
            defaultActiveKey="En"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="En" title="EN">


              <p style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif", textAlign: "center" }}><img width="107" src="https://ivyis.org/images/ivy_logo.png" alt="image" style={{ float: "right" }} /></p>
              <p style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif", textAlign: "center" }}><br /></p>
              <p style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif", textAlign: "center" }}><br /></p>
              <p style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif", textAlign: "center" }}><strong><em><u><span style={{ fontSize: "16px", lineHeight: "107%" }}>Acknowledgment of the student's parent</span></u></em></strong></p>
              <p style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif", textAlign: "center" }}><strong><em><u><span style={{ fontSize: "16px", lineHeight: "107%" }}><span style={{ textDecoration: "none" }}>&nbsp;</span></span></u></em></strong></p>
              <p style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif" }}><br /></p>
              <p style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif" }}><br /></p>
              <p style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif" }}><span style={{ fontSize: "16px", lineHeight: "107%" }}>As an approach to giving our students the entire learning experience at IVY STEM international schools, we promised always to deliver high-end quality education.</span></p>
              <p style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif" }}><span style={{ fontSize: "16px", lineHeight: "107%" }}>We, IVY STEM international school, declare to provide Chromebooks to grades levels (1-8) / laptops to grades levels (9-10)&nbsp;</span></p>
              <p style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif" }}><span style={{ fontSize: "16px", lineHeight: "107%" }}>It&rsquo;s worth mentioning that the devices are the school&rsquo;s property and the school has full ownership of them.</span></p>
              <p style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif" }}><span style={{ fontSize: "16px", lineHeight: "107%" }}>The Chromebooks/Laptops are not paid for, but are provided by the school to enhance and facilitate the learning experience of IVY students.</span></p>
              <p style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif" }}><span style={{ fontSize: "16px", lineHeight: "107%" }}>The students/parents are required to return them back to the school upon request.</span></p>
              <p style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif" }}><span style={{ fontSize: "16px", lineHeight: "107%" }}>In case of any damage to any of the provided devices, the guardian has to pay its worth to the school.</span></p>
              <p style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif" }}><span style={{ fontSize: "16px", lineHeight: "107%" }}>In case of missing any of the provided devices, the guardian has to pay its worth to the school.</span></p>
              <p style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif" }}><span style={{ fontSize: "16px", lineHeight: "107%" }}>This is the student/guardian&apos;s responsibility to maintain the property in good condition and return it back to the school upon request.</span></p>
              <p style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif" }}><span style={{ fontSize: "16px", lineHeight: "107%" }}>By clicking accept you, therefore, agree to the terms and conditions.</span></p>
              <p style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif" }}><strong><span style={{ fontSize: "16px", lineHeight: "107%" }}>Note:</span></strong></p>
              <p style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif" }}><span style={{ fontSize: "16px", lineHeight: "107%" }}>1-Chromebooks/laptops will only be provided for students who have fully paid the first installment to the finance department.</span></p>
              <p style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif" }}><span style={{ fontSize: "16px", lineHeight: "107%" }}>2-Upon payment the guardian will be asked to sign a declaration form!</span></p>



            </Tab>
            <Tab eventKey="عربى" title="عربى">
              <p style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif", textAlign: "center" }}><img width="107" src="https://ivyis.org/images/ivy_logo.png" alt="image" style={{ float: "right" }} /></p>
              <p style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif", textAlign: "center" }}><br /></p>
              <p style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif", textAlign: "center" }}><br /></p>
              <p style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif", textAlign: "center" }}><em><span style={{ fontSize: "16px", lineHeight: "107%" }}>&nbsp;</span></em></p>
              <p style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif", textAlign: "center" }}><span style={{ fontSize: "16px", lineHeight: "107%" }}>نموذج اقرار الطالب وولي الامر &nbsp;</span></p>
              <p style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif", textAlign: "right" }}><span style={{ fontSize: "16px", lineHeight: "107%" }}>&nbsp;</span></p>
              <p dir="RTL" style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif", textAlign: "right" }}><span dir="LTR" style={{ fontSize: "16px", lineHeight: "107%" }}>تحرص مدرسة أيفي الدولية علي إعطاء طلابنا تجربة التعليم الكاملة و كما وعدنا بتقديم تعليم بطرق مختلفة و جوده عالية</span></p>
              <p dir="RTL" style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif", textAlign: "right" }}><span dir="LTR" style={{ fontSize: "16px", lineHeight: "107%" }}>&nbsp;</span></p>
              <p dir="RTL" style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif", textAlign: "right" }}><span dir="LTR" style={{ fontSize: "16px", lineHeight: "107%" }}>نفيد نحن مدرسة ايفي الدولية علي تسليم الطلاب جهاز كروم بوك و ذلك من بداية من المراحلة الصف الاول الابتدائي الي الصف السادس الابتدائي و الصف الاول الاعدادي الي الصف الثاني الاعدادي . و تسليم لابتوب الي الصف الثالث الاعدادي و الصف الاول الثانوي .</span></p>
              <p dir="RTL" style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif", textAlign: "right" }}><span dir="LTR" style={{ fontSize: "16px", lineHeight: "107%" }}>&nbsp;</span></p>
              <p dir="RTL" style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif", textAlign: "right" }}><span dir="LTR" style={{ fontSize: "16px", lineHeight: "107%" }}>مع العلم ان الاجهزة (لاب توب _ كروم بوك ) ملكيه خاصة للمدرسة كعهدة شخصية على سبيل الأمانة لإستخدامه فى النظام التعليمى الخاص بمدارس (أى فى إنترناشونال) وأتعهد بالحفاظ &nbsp;عليه وإرجاعه إلى إدارة المدرسة فى حالة إذا طلب منى ذلك &nbsp;تسديد قيمته .</span></p>
              <p dir="RTL" style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif", textAlign: "right" }}><span dir="LTR" style={{ fontSize: "16px", lineHeight: "107%" }}>في حالة تلف أو فقد الجهاز يتم دفع قيمته المحددة من قبل المدرسة, و الحفاظ عليه في حالة جيدة هي مسؤولية الطالب</span></p>
              <p dir="RTL" style={{ margin: "0in", marginBottom: "8.0pt", lineHeight: "107%", fontSize: "15px", fontFamily: "Calibri,sans-serif", textAlign: "right" }}><strong><span dir="LTR" style={{ fontSize: "16px", lineHeight: "107%" }}>ملحوظة</span></strong></p>
              <ol style={{ listStyleType: "decimal", marginLeft: "-0.5in", textAlign: "right" }}>
                <li><span dir="LTR" style={{ fontSize: "16px", lineHeight: "107%" }}>سوف يتم &nbsp;تسليم &nbsp;كروم بوك/ لاب توب فقط للطلاب الذين دفعوا القسط الأول كاملاً&nbsp;</span><span style={{ lineHeight: "107%", fontFamily: '"Arial",sans-serif' }}>.</span></li>
                <li><span dir="LTR" style={{ fontSize: "16px", lineHeight: "107%" }}>عند الدفع سيطلب من ولي الأمر التوقيع على هذا الإقرار</span><span style={{ lineHeight: "107%", fontFamily: '"Arial",sans-serif' }}>.</span></li>
              </ol>
            </Tab>
          </Tabs>

          <Row>
            <div className=' justify-content-center' >
              <div className=' m-auto text-left col-12 col-md-6 col-lg-4' >
                <label>
                  <span className='text-danger'>*</span> Please sign below.
                </label>
                </div>
                <div className=' m-auto text-left row col-12 col-md-6 col-lg-4' >
                <SignatureCanvas canvasProps={{ className: "border border-primary"}} ref={(ref) => { sigPad = ref }} />
                <button className='btn btn-warning' onClick={(e) => { e.preventDefault(); clear(); return false; }}>
                  Clear
                </button>
              </div>

              {/* <button onClick={(e) => { e.preventDefault(); trim(); return false; }}>
                Trim
              </button> */}

            </div>
          </Row>
          <FormGroup className="justify-content-center text-center">
            <label>3) I Confirm.</label>
            <Row className="justify-content-center">
              <Col md={3}>
                <div className="form-check">
                  <input className="form-check-input" type="radio" required name="q3" id="yes3" value="yes3" checked={state.confirm ==true ? true : false}   onChange={(e)=> {
                             setState({...state,confirm:true})
                        }} />
                  <label className="form-check-label" htmlFor="yes3">
                  Yes
                  </label>
                </div>
              </Col>
              <Col md={3}>
              <div className="form-check">
                  <input className="form-check-input" type="radio" required name="q3" id="no3" value="no3" checked={state.confirm ==false? true : false}   onChange={(e)=> {
                             setState({...state,confirm:false})
                        }} />
                  <label className="form-check-label" htmlFor="no3">
                  No 
                  </label>
                </div>
              </Col>
              </Row>
              </FormGroup>


          <Row className="justify-content-center" >

            <Col md={3}>
              <Input type="submit" className="btn btn-primary mt-3" style={{ backgroundColor: "blue" }} value="Submit" />

            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
      </ModalFooter>
    </Modal>

  )
}

export default Chromebook;