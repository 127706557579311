import React, { useEffect, useState } from "react";
import { CardHeader } from "reactstrap";
import ColoredLine from "./ColoredLine";
import LineChart from "./LineChart";



const SubjectMapGrowth = (props: any) => {


    const [labels, setLabels] = useState<any>([]);
    const [values, setValues] = useState<any>([]);
    const [selectedSubjectData, setSelectedSubjectData] = useState<any>();
    const [availableSeasons, setAvailableSeasons] = useState<any>();

    const [lastRecord, setLastRecord] = useState<any>([]);
    const [min, setMin] = useState<any>();
    const [max, setMax] = useState<any>();


    useEffect(() => {
        let data = props.subjectData?.data;
        setLastRecord(data[data.length - 1])
        const tempLabels = [...labels];
        const tempValues = [...values];
        for (let index = 0; index < data.length; index++) {
            tempLabels.push(data[index].term_name);
            tempValues.push(data[index].test_rit_score);

        }
        setMin(Math.min(...tempValues))
        setMax(Math.max(...tempValues))
        setLabels(tempLabels);
        setValues(tempValues);

    }, [props.subjectData?.data])


    useEffect(()=>{


        setSelectedSubjectData(props.subjectData);
        setAvailableSeasons(props.subjectData?.available_seasons)

    },[props.subjectData?.data])

    const ordinal = (n: any) => {
        var s = ["th", "st", "nd", "rd"];
        var v = n % 100;
        return n + (s[(v - 20) % 10] || s[v] || s[0]);
    }

    const mapQuintil = (quintil: any) => {

        let value = quintil?.trim()
        if (value == "Avg") return "Average";
        else if (value == "HiAvg") return "High Average ";
        else if (value == "LoAvg") return "Low Average";
        else return quintil;

    }

    const growthPeriodChange = (e:any)=>{
        let tempSelectedSubject = availableSeasons.find((season:any)=>season.id == e.target.value);
        setSelectedSubjectData(tempSelectedSubject)
    }

    return (

        <div>

            <CardHeader>
                <h4 className="title"> {props.subjectData?.subject_course}</h4>
                {availableSeasons?.length > 0 ? (
                    <div>
                        <label className="">Select growth period</label>

                    <select onChange={(e:any)=>{growthPeriodChange(e)}} className="form-control w-50">
                    {availableSeasons?.map((season:any)=>{
                        if(season.roster == props.subjectData?.roster){
                            
                            return (
                                 <option value={season.id} selected>{season.roster}</option>
                                 )
                         }else{
                             return (
                                 <option value={season.id}>{season.roster}</option>
                                 )
                                 
                                }
                            })}
                </select>
                            </div>
                ) :
                <select>
                   
                            <option>No Data available</option>
                   
                </select>
                }
                
            </CardHeader>
            <div className="m-5 row">
                {/* Achievement */}
                <div className="col-6">

                    <h4 className="mb-3"> {`${mapQuintil(lastRecord?.achievement_quintile)} Achievement ${ordinal(lastRecord.test_percentile)} Percentile`}</h4>
                    <LineChart subject={props.subjectData?.subject_course} labels={labels} data={values} min={min} max={max} />

                    <div className="mt-3">
                    Your child's overall score (RIT score) was a {lastRecord.test_rit_score} . Your child was in the {ordinal(lastRecord.test_percentile)} percentile in the {lastRecord.term_name}, which means they scored better than {lastRecord.test_percentile}% of their peers.
                       
                    </div>
                </div>


                <div className="col-none col-md-1  " style={{ borderLeft: "3px solid grey", maxHeight: "400px" }}></div>

             {/* Growth */}
                <div className="col-5">

                    {selectedSubjectData?.growth_quintile ? (
                        <div>

                            <h4 className="mb-3"> {`${mapQuintil(selectedSubjectData?.growth_quintile)} Growth ${ordinal(selectedSubjectData.conditional_growth_percentile)} Percentile`}</h4>
                            <ColoredLine growthPercentile={selectedSubjectData.conditional_growth_percentile} text={`${ordinal(selectedSubjectData.conditional_growth_percentile)}`} />
                               <div>
                               Your child's growth from {selectedSubjectData?.roster} is in the {ordinal(selectedSubjectData.conditional_growth_percentile)} percentile, which means they made more progress than {selectedSubjectData.conditional_growth_percentile}% of their peers.
                               </div>
                        </div>
                    ) : "No Growth data to show"}

                </div>
            </div>


        </div>

    )

}



export default SubjectMapGrowth