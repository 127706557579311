import React, { Component, useState, useMemo } from "react";
import { Input, Form } from '../../MyComponents/myForms';
import '../../../css/login.css';
import { Translate } from '../../../helpers/Translate';
import Select from 'react-select';

import { actionCreators as ParentCreator } from "../../../store/Parent";
import { connect } from "react-redux";
import {Hourglass as Loader} from "react-loader-spinner";
import Swal from "sweetalert2";

import { CountryDropdown as Countries} from 'react-country-region-selector'


const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
    GetParent: (data: any) =>
        dispatch(ParentCreator.GetParent(data)),
    UpdateParent: (data: any) =>
        dispatch(ParentCreator.UpdateParent(data)),
    ClearParent: (data: any) =>
        dispatch(ParentCreator.ClearParent(data)),

});


function mapStateToProps(state: any) {
    return {
        guardian: state.Parent.guardian,
        guardianLoading: state.Parent.guardianLoading,
        updateGuardianLoading: state.Parent.updateGuardianLoading,
        error: state.Parent.error,
        updateMsg: state.Parent.updateMsg,

    };
}
const connector = connect(mapStateToProps, mapDispatchToProps);

var  fnSubmit = true;
var  mnSubmit = true;
var  lnSubmit = true;
var eSubmit = true;
var ssnSubmit = true;
var occSubmit = true;




class EditProfile extends Component<any, any> {
    t = new Translate();
    constructor(props: any) {
        super(props)
        this.state = {
            firstname: "",
            middlename: "",
            lastname: "",
            username: "",
            ssn: "",
            ocupation: "",
            country: "",
            mobile: "",
            education_level: "",

            countryName: null,
            image: null,

            phone: "Must be Mobile Number ex: 0123456789101",
            email: "",

          

        }



        this.onDrop = this.onDrop.bind(this);
        this.onSelectCountry = this.onSelectCountry.bind(this);
        this.onSaveProvfile = this.onSaveProvfile.bind(this);
        this.onCancel = this.onCancel.bind(this);

    }


    componentDidMount() {
        if (this.props.guardian) {
            this.setState({ ...this.props.guardian })
        }


    }
    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps.guardian != this.props.guardian) {
            this.setState({ ...this.props.guardian })
        }
    }
    onSelectCountry(event: any) {
        this.setState({ country: event.target.value })


    }

    handlePhone = (e: any) => {
        this.setState({
            phone: this.state.phone
        })
    }




    onDrop(picture: any) {
        this.setState({ image: picture[0] });
    }
    
    onSaveProvfile() {
        if (fnSubmit == true && mnSubmit == true && lnSubmit == true &&
            eSubmit == true && ssnSubmit == true && occSubmit == true) {
            const formData = new FormData();
         // Update the formData object
            for (const property in this.state) {
                formData.append(
                    property,
                    this.state[property],
                );
            }
            this.props.UpdateParent(formData)
        }
        else{
            Swal.fire({
                title: "Would you please check your data?",
                text: "Please enter valid inputs to be able to edit yor data",
                icon: "warning"
              })              
           
        }
    }

    onCancel() {
        this.props.ClearParent()
        this.props.Navigate("/dashboard/viewprofile")
    }

    handleChange(field: any, e: any) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    render() {


        let error = null;
        if (this.props.error) {
            Swal.fire({
                title: "Error",
                icon: "error",
                text:this.props.error
            }).then(() => {
                this.onCancel();
            });
        }

        let forSuccess = null;
        if (this.props.updateMsg) {
            Swal.fire({
                title: "Success",
                icon: "success",
                text:this.props.updateMsg
            }).then(() => {
                this.onCancel();
            });
        }


        return (
            !this.props.guardianLoading && !this.props.updateGuardianLoading ? (


                <div className="container rounded bg-white mt-5 mb-5">
                    {error}
                    {forSuccess}
                    <Form >

                        <div className="row">

                            <div className="col-md-3 border-right">
                                <div className="d-flex flex-column align-items-center text-center p-3 py-5">
                                    <img className="rounded-circle mt-5" src="" />
                                    <span className="font-weight-bold">Profile Picture</span>

                                    {/* <ImageUploader
                                        withIcon={true}
                                        withPreview={true}
                                        singleImage={true}
                                        buttonText='Choose image'
                                        onChange={this.onDrop}
                                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                        maxFileSize={5242880}
                                    /> */}

                                </div>
                            </div>
                            <div className="col-md-9 border-right">
                                <div className="p-3 py-5">
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <h4 className="text-right">Profile Settings</h4>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-6">
                                            <label >First Name: </label>
                                            <input type="text" className="form-control"
                                                id= "firstname"
                                                name="firstname"
                                                placeholder= "Place your ssn here ^-^"
                                                value={this.state.firstname} //Optional.[String].Default: "".
                                                onChange={(e: any) => { this.setState({ firstname: e.target.value }) }}
                                                onBlur={e => { }} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                                                required
                                                pattern="[A-Za-z ا-ي]{3,100}"
                                            />
                                            <br />

                                        </div>

                                        <div className="col-md-6">
                                            <label >Middle Name: </label>
                                            <input type="text" className="form-control"
                                                    id= "middlename"
                                                    name="middlename"
                                                    placeholder= "Place your ssn here ^-^"
                                                value={this.state.middlename} //Optional.[String].Default: "".
                                                onChange={(e: any) => { this.setState({ middlename: e.target.value }) }}
                                                onBlur={e => { }} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                                                required
                                                pattern="[A-Za-z ا-ي]{3,100}"
                                            />
                                            <br />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-6">
                                            <label >Last Name: </label>
                                            <input className="form-control" id="lastname" placeholder="Place your ssn here ^-^"
                                                name="lastname"
                                                value={this.state.lastname} //Optional.[String].Default: "".
                                                onChange={(e: any) => { this.setState({ lastname: e.target.value }) }}
                                                onBlur={e => { }} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                                                required
                                                pattern="[A-Za-z ا-ي]{3,100}"
                                            />
                                            <br />
                                        </div>
                                        <div className="col-md-6">
                                            <label >Email: </label>
                                            <input 
                                                className="form-control"
                                                type="text"
                                                id="email"
                                                name="email"
                                                placeholder="Place your email here ^-^"
                                                value={this.state.username} //Optional.[String].Default: "".
                                                onChange={(e: any) => { this.setState({ username: e.target.value }) }}
                                                onBlur={e => { }} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                                                required
                                                pattern="[A-Za-z0-9 \- _ \.]+@[A-Za-z0-9 \- _ \]+\.[A-Za-z]{1,5}"
                                            />


                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-10">
                                            <label >National ID / SSN: </label>
                                            <input
                                                type="number"
                                                id="ssn"
                                                name="ssn"
                                                className="form-control"
                                                placeholder="Place your ssn here ^-^"
                                                value={this.state.ssn} //Optional.[String].Default: "".
                                                onChange={(e: any) => { this.setState({ ssn: e.target.value }) }}
                                                onBlur={e => { }} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                                                pattern="[0-9 \w]{5,14}"
                                                required
                                            />
                                            <br />


                                        </div>
                                        <div className="col-md-10">
                                            <label >Occupation: </label>
                                            <input
                                                type="text"
                                                id="ocupation"
                                                name="ocupation"
                                                placeholder="Pleae mention your ocupation"
                                                value={this.state.ocupation} //Optional.[String].Default: "".
                                                onChange={(e: any) => { this.setState({ ocupation: e.target.value }) }}
                                                onBlur={e => { }} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                                                required
                                            />
                                            <br />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-6"><label >Country: </label>

                                            <Countries 
                                                name="country"
                                                value={this.state.country}
                                                onChange={(e: any) => this.onSelectCountry(e)} />

                                        </div>
                                        <div className="col-md-6"><label >Mobile: </label>

                                            <input type="number" 
                                                placeholder="Enter mobile number"
                                                value={this.state.mobile}
                                                className="form-control"
                                                name="mobile"
                                                onChange={(e: any) => {
                                                    this.setState({ mobile: e.target.value })
                                                }}
                                                required
                                                pattern="[0-9 \-]{8,15}"
                                            />

                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-md-6">
                                            <label> Highest Education Level:
                                                <select
                                                    className="form-control"
                                                    aria-label="Default select"
                                                    name="education_level"

                                                    value={this.state.education_level}
                                                    onChange={(e) => this.setState({ education_level: e.target.value })}
                                                >
                                                    <option value="">Choose Education Level</option>
                                                    <option value="PHd.">Phd.</option>
                                                    <option value="MSc.">Msc. </option>
                                                    <option value="BSc.">Bsc. </option>
                                                </select>

                                            </label>
                                        </div>

                                    </div>
                                    <div className="mt-5 text-center">
                                        <button className="btn btn-primary profile-button" onClick={this.onSaveProvfile}
                                            type="button">Save Profile</button></div>
                                </div>
                            </div>

                        </div>

                    </Form>
                </div>

            ) : (
                <div className="d-flex justify-content-center align-items-center mt-5">
                    <Loader height={100} width={100} />
                </div>
            )
        )
    }

}



export default connector(EditProfile);

