import React, { useState } from 'react';
import { useSelector } from 'react-redux';


import { Row, Col, Card, CardImg, CardBody, CardTitle, ModalBody, ModalFooter, Modal, ModalHeader, Form, Input, FormGroup } from 'reactstrap';
import { NavLink, useNavigate } from 'react-router-dom';

import "../parentpages/Home/Home.css";
import { Translate } from '../../../helpers/Translate';


//const img1 = require("./assets/img/schoology_logo.png").default;

import img12 from "../parentpages/Home/assets/img/menu.png"
import img2 from "../parentpages/Home/assets/img/ivy_transportation.png";

import wallet from "../parentpages/Home/assets/img/wallet.png";
import installmentsicon from "../parentpages/Home/assets/img/installments.jpg";
import serviceicon from "../parentpages/Home/assets/img/servicesubscription.jpg";


const t = new Translate();




const Finance = () => {

  const navigate = useNavigate();
  const height = "100Px";
  const transjwt: any = useSelector(
    (state: any) => state.Parent.transjwt
  );
  const Parent: any = useSelector(
    (state: any) => state.Parent
  );
  const schoologyUserName: any = useSelector(
    (state: any) => state.Parent.schoologyUserName
  );



 


  return (
    <>

      <div className="content">
        <Row>
         
          <Col lg={4} md={4} sm={6} xs={6}>
            <NavLink to={"/dashboard/schoolfees"} >
              <Card className="frame text-center p-2">
                <br />
                <CardImg top height={height} src={installmentsicon} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    {t.translate("School Installments")}
                  </CardTitle>
                </CardBody>
              </Card>
            </NavLink>
          </Col>
          <Col lg={4} md={4} sm={6} xs={6}>
            <NavLink to={"/dashboard/ewallet"} >
              <Card className="frame text-center p-2">
                <br />
                <CardImg top height={height} src={wallet} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    {t.translate("Cafeteria")}
                  </CardTitle>
                </CardBody>
              </Card>
            </NavLink>
          </Col>
          <Col lg={4} md={4} sm={6} xs={6}>
            <NavLink to={"/dashboard/servicesubscription"} >
              <Card className="frame text-center p-2">
                <br />
                <CardImg top height={height} src={serviceicon} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    {t.translate("Service subscriptions")}
                  </CardTitle>
                </CardBody>
              </Card>
            </NavLink>
          </Col>
        </Row>
        <br />
      </div>
    </>
  );
};

export default Finance;