import React from 'react'
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Translate } from "../../helpers/Translate";
const t = new Translate();

const nameMapped: any = {
    dashboard: "Dashboard",
    home: "Home",
    ewallet: "Smart E-Wallet",
    viewstudents: "Students",
    schoolfees: "School Installments",
    servicesubscription: "Service subscriptions",
    finance: "Smart Wallet",
    latearrival: "Late Arrival",
    earlydismissal: "Early Dismissal",
    excuses: "Excuses",
    meetings: "Meetings",
    academicinterventions: "Academic Interventions",
    interventions: "Character Interventions",
    clinicvisits: "Clinic Visits",
    WallOfLove: "Wall Of Love",
    cafeteria: "Cafeteria",
    resturant: "Resturant",
    transaction: "Transaction",
    payments: "Payments",
    subscriptions: "Subscriptions",
    installments: "Installments",
    parentmenu: "Parent Menu",
    profilemenu: "Profile Menu",
    changepassword: "Change Password",
    editprofile: "Edit Profile",
    viewprofile: "View Profile",
    chatcontent: "Chat Content",
    chatlist: "Chat List",
    chatbody: "Chat Body",
    dopayment: "Do Payment",
    classactivities: "Class Activities",
    cafeteriamenu: "Cafeteria Menu",
    "Reinforcement-point": "Reinforcement Point",
    "progress-report": "Progress Report",
    "map-growth": "Map Growth",
}

const Breadcrumbs = () => {
    const location = useLocation();
    const pathnames = location?.pathname?.split('/')?.filter(x => x);
    if (!pathnames || (!pathnames?.length)) {
        return null;
    }
    const currentPage = pathnames[pathnames?.length - 1] ?? "";
    return (
        <div>

            <h1 className="mt-4">{t.translate(nameMapped?.hasOwnProperty(currentPage) ? nameMapped[currentPage] : currentPage)}</h1>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    {/* <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                    </li> */}
                    {pathnames.map((value, index) => {
                        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                        return (
                            <li key={to} className="breadcrumb-item">
                                {/* <Link to={to}>{t.translate(value)}</Link> */}
                                <Link to={to}>{t.translate(nameMapped?.hasOwnProperty(value) ? nameMapped[value] : value)}</Link>
                            </li>
                        );
                    })}
                </ol>
            </nav>
        </div>
    );

}

export default Breadcrumbs