import { actionCreators } from "../../../../store/Parent";

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dispatch } from "redux";
import { NavLink } from "react-router-dom";
import { ReactComponent as CogIcon } from ".././icons/cog.svg";
import avatar from "../../../../assets/avatar.jpeg";
import { Translate } from "../../../../helpers/Translate";

const DropdownMenu = () => {
  let t = new Translate();
  const [activeMenu, setActiveMenu] = useState("main");
  const [menuHeight, setMenuHeight] = useState(0);
  const dropdownRef = useRef(null);
  const Navigate = useNavigate();

  const dispatch: Dispatch<any> = useDispatch();
  const guardian: any = useSelector((state: any) => state.Parent.guardian);

  const LogOut = (e: any) => {
    dispatch(actionCreators.Logout());
    Navigate("/login");
  };

  useEffect(() => {
    if (dropdownRef.current !== null && dropdownRef.current !== undefined) {
      let current: any = dropdownRef.current;
      if (current.firstChild !== null && current.firstChild !== undefined) {
        setMenuHeight(current.firstChild.offsetHeight);
      }
    }
  }, []);

  function calcHeight(el: any) {
    const height = el.offsetHeight;
    setMenuHeight(height);
  }

  function DropdownItem(props: any) {
    return (
      <span className="menu-item" onClick={() => setActiveMenu(props.goToMenu)}>
        <span className="icon-button">{props.leftIcon}</span>
        {props.children}
        <span className="icon-right">{props.rightIcon}</span>
      </span>
    );
  }

  function nextPath(path: any) {
    Navigate(path);
  }

  return (
    <div className="dropdown" style={{ height: menuHeight }} ref={dropdownRef}>
      <div className="menu">
        <DropdownItem
          leftIcon={
            <img
              src={
                guardian.driveid
                  ?guardian.driveid?.includes("https://")?guardian.driveid: `https://drive.google.com/uc?id=${guardian.driveid}&export=download`
                  : `${avatar}`
              }
              width="50"
              height="60"
              className="rounded-circle"
            />
          }
        >
          <div
            className="nav-linkk"
            onClick={() => nextPath("/dashboard/viewprofile")}
          >
            {t.translate("My Profile")}
          </div>
        </DropdownItem>

        <DropdownItem width="20px" leftIcon={<CogIcon />}>
          <NavLink className="nav-linkk" to={"/dashboard/changepassword"}>
            {t.translate("Change Password")}
          </NavLink>
        </DropdownItem>
        <DropdownItem width="20px" leftIcon={<CogIcon />}>
          <NavLink className="nav-linkk" to={"/login"} onClick={LogOut}>
            {t.translate("Logout")}
          </NavLink>
        </DropdownItem>
        <br />
      </div>
    </div>
  );
};

export default DropdownMenu;
