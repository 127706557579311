import "bootstrap/dist/css/bootstrap.css";
import * as React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import AppStore from "./store/configureStore";
import App from "./App";
import { BrowserRouter as Router } from 'react-router-dom';
import "core-js/stable";
import "@babel/polyfill";

//STEP 1:
//create components using React.lazy
const EnglishTheme = React.lazy(() => import("./Themes/ThemeEn"));
const ArabicTheme = React.lazy(() => import("./Themes/ThemeAr"));

//STEP 2:
//create a parent component that will load the components conditionally using React.Suspense
const ThemeSelector = ({ children }: any) => {
  const CHOSEN_THEME = localStorage.getItem("preferred_lang") || "en";
  return (
    <>
      <React.Suspense fallback={<></>}>
        {CHOSEN_THEME === "en" && <EnglishTheme />}
        {CHOSEN_THEME === "ar" && <ArabicTheme />}
      </React.Suspense>
      {children}
    </>
  );
};

// Create browser history to use in the Redux store
//const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const container:any = document.getElementById('root');
const root= createRoot(container)
root.render(
  <Provider store={AppStore}>
      <ThemeSelector>
        <Router>
          <App />
        </Router>
      </ThemeSelector>
  </Provider>,
);

//registerServiceWorker();
