import React, { Dispatch } from "react";

import axios from "axios";
import Loader from "react-loader-spinner";
import Swal from 'sweetalert2'


class PayService extends React.Component<any, any> {
  amount = 0;
  Checkout: any;
  order: string = "";
  session: string = "";
  constructor(props: any) {
    super(props);
    var w: any = window;
    w.paycancel = () => {
      this.paycancel();
    };
    w.payerror = (p: any) => {
      this.payerror(p);
    };
    w.paycomp = () => {
      this.paycomp();
    };
    w.paytimeout = () => {
      this.paytimeout();
    };
    this.state = {
      loading: true,
      errorMsg: null,
      successMsg: null,
      paymentSelect: "",
      selectedFile: null,
      image: null,
    };
    this.startPaymentProcess();
    //this.paycomp();
  }
  updateamount = (e: any) => {
   
    this.amount = e.target.value;
  
  };
  startPaymentProcess = () => {
  
    //this.setState({ loading: true });
    axios
      .post(`${process.env.REACT_APP_BASEURL}/payment/servicepayment/createsession.php`, {  ...this.props.installment,students : this.props.students  , paidamount: this.props.amount})
      .then((res: any) => {
        
        this.order = res.data.data.order;
        this.session = res.data.data.result.session.id;
        //this.order = this.dat
      
        this.configureCheckout();
        var w: any = window;
        w.Checkout.showLightbox();

        //dispatch(fetchAllBusesSuccess(res.data.data));
      })
      .catch((err) => {
        this.setState({ loading: false });
       
        if (err.response) {
       
          //dispatch(fetchAllBusesFail(err.response.data.msg));
        } else {
          //dispatch(fetchAllBusesFail('Internet Connection Failed'));
        }
      });
  };
  payerror = (error: any) => {
    this.setState({ errorMsg: " Error: " + error, loading: false });
  };
  paycancel = () => {
    
    this.setState({ errorMsg: "Payment cancelled", loading: false });
    this.props.onFinish("Canceled");
  };
  paycomp = () => {
   
    // check from BE
    axios
      .post(process.env.REACT_APP_BASEURL+"/payment/servicepayment/checkpayment.php", {
        order: this.order,
         paidamount: this.props.amount,
         students : this.props.students ,
     
        ...this.props.installment
      })
      .then((res: any) => {
       
        
            
            Swal.fire({
                title: "Success",
                footer: 'Copyright 2021',
                //html: <i></i>,
                icon: 'success',
                didOpen: () => {
                // `MySwal` is a subclass of `Swal`
                //   with all the same instance & static methods
                //MySwal.clickConfirm()
                }
            }).then(()=>{
          this.props.onFinish(res.data.data);
             

            }) 
       
        
      }).catch(()=>{
        Swal.fire({
            title: "ERRORR",
            footer: 'Copyright 2021',
            //html: <i></i>,
            icon: 'error',
            didOpen: () => {
            // `MySwal` is a subclass of `Swal`
            //   with all the same instance & static methods
            //MySwal.clickConfirm()
            }
        }).then((result:any)=>{
           
            this.props.onFinish(null);
        })
      })
  };
  paytimeout = () => {
    this.setState({ loading: false });
    this.setState({ errorMsg: "Payment timeout" });
    
  };
  configureCheckout = () => {
    var w: any = window;
    w.Checkout.configure({
      merchant: "CIB701182",
      order: {
        amount: this.props.amount,
        currency: "EGP",
        description: "Transportation Fees",
        id: this.order,
      },
      session: {
        id: this.session,
      },
      interaction: {
        operation: "PURCHASE",
        merchant: {
          name: "IVY STEM International Schools",
        },
        locale: "en_US",
      },
    });
  };

  openAddFileInputHandler = () => {
    const fileInput = document.getElementById("addFile") as HTMLInputElement;
    fileInput.click();
  };

  addFileHandler = (e: any) => {
    const file: any = e.target.files[0];

    if (file && file.size > 0) {
      var reader: any = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (reader.result.split(":")[1].split("/")[0] === "image") {
          this.setState({ image: reader.result, selectedFile: file });
        }
      };
    }
  };

  onUploadImage = () => {
    const formData = new FormData();
    formData.append(
      "file",
      this.state.selectedFile,
      this.state.selectedFile.name
    );

    // axios
    // .post(`api/v1/client/file/${folderId}`, formData)
    // .then((res) => {
    //   // dispatch(uploadFileSuccess(res.data.file));
    // })
    // .catch((err) => {
    
    // });
  };

  onClearMsgHandler = () => {
    this.setState({ errorMsg: null, successMsg: null });
  };

  render() {
    // let paymentDiv = document.getElementById('payContainer') as HTMLDivElement;
    // if(paymentDiv){
    //   this.setState({loading:false})
    // }

    let forError = null;
    if (this.state.errorMsg) {
      Swal.fire({
        title:"Error",
        icon:"error",
        text:this.state.errorMsg
      }).then(() => {
        this.onClearMsgHandler();
      })
    }

    let forSuccess = null;
    if (this.state.successMsg) {
      Swal.fire({
        title:"Success",
        icon:"success",
        text:this.state.successMsg
      }).then(()=>{
        this.onClearMsgHandler()
      })

    }

    return this.state.loading === false ? (
      <div className="row justify-content-center">
        {forError}
        {forSuccess}
        <div className="row justify-content-center align-items-center text-center">


        </div>

        
      </div>
    ) : (
      <div className="d-flex justify-content-center align-items-center mt-5">
        {/* <Loader type="ThreeDots" color="#00BoFFF" height={100} width={100} /> */}
        <div className="mt-5"></div>
        Please wait
      </div>
    );
  }
}

export default PayService;
