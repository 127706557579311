import React, { useEffect, useState } from "react";
import MDBDataTableV5 from "../../CustomDataTable";
import { Spinner, Card, CardBody, CardHeader } from "reactstrap";
import Swal from "sweetalert2";
import axios from "axios";
import { Translate } from "../../../helpers/Translate";
import "react-datetime/css/react-datetime.css";
import MeetingModal from "./MeetingsModal";

const Intervention: React.FC = () => {
  let t = new Translate();
  const defaultTable={
    columns: [
      {
        label: "#",
        field: "id",
      },
      {
        label: t.translate("Students"),
        field: "stdids",
      },
      {
        label: t.translate("Subject"),
        field: "subject",
      },
      {
        label: t.translate("Meeting Type"),
        field: "type",
      },
      {
        label: t.translate("Teacher"),
        field: "teacher",
      },
      {
        label: t.translate("Date&Time"),
        field: "date",
      },
      {
        label: t.translate("Location"),
        field: "location",
      },
      {
        label: t.translate("Status"),
        field: "status",
      },
      {
        label: t.translate("View"),
        field: "view",
      },
    ],
    rows: [
      {
        id: <Spinner color="info" />,
        stdids: <Spinner color="info" />,
        subject: <Spinner color="info" />,
        type: <Spinner color="info" />,
        teacher: <Spinner color="info" />,
        location: <Spinner color="info" />,
        status: <Spinner color="info" />,
        view: <></>,
      },
    ],
  }
  const [intDataTable, setIntDataTable] = useState(defaultTable);
  var now = new Date();
  now.setDate(now.getDate() + 1);
  now.setHours(10);
  now.setMinutes(0);
  now.setMilliseconds(0);

  const [id,setId] = useState(null);
  const [modal, setModal] = useState(false);


  useEffect(() => {
    try {
      axios.get(`${process.env.REACT_APP_BASEURL}student/meeting/getmeetingutils.php`)
        .then((res) => {
            let ser = 1;
            let newRows = res.data.data.meetings.map((i: any, key: any) => {
              return {
                ...i,
                id: ser++,
                stdids: i.students,
                subject: i.subject,
                teacher: i.fullname,
                location:
                  i.location == undefined ? null : i.location?.search(
                    "https"
                  ) != -1 ? (
                    <a style={{ color: "blue" }} href={i.location}>
                      {" "}
                      Online Link
                    </a>
                  ) : (
                    i.location
                  ),
                status: i.status,
                date:i.date,
                view: (
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      handleview(i.id);
                    }}
                  >
                    {t.translate("View")}
                  </button>
                ),
              };
            });
            setIntDataTable({...defaultTable, rows: newRows });
          });
    } catch {
      Swal.fire("Error while grapping Meetings!", "", "error")
        .then(() => {
        })
        .finally(() => {
          setModal(false);
        });
    }
  }, [modal]);

  
  const handleOpen = () => {
    setId(null);
    setModal(true);
  };

  const handleview = (id: any) => {
      setId(id);
      setModal(true);
  };


  return (
    <>
      <div className="row justify-content-center w-100 mx-0 my-5 linkssec">
        <Card>
          <CardHeader>{t.translate("Meetings")}</CardHeader>
          <hr />
          <CardBody className="bg-light">
            <button
              className="btn btn-primary my-3"
              onClick={() => {
                handleOpen();
              }}
            >
              {t.translate("Add Appointment")}
            </button>
            <MDBDataTableV5
              hover
              responsive
              borderless={false}
              bordered
              striped
              entriesOptions={[5, 20, 25]}
              entries={5}
              pagesAmount={4}
              data={intDataTable}
              fullPagination
              searchTop
              searchBottom={false}
            />
          </CardBody>
        </Card>
      </div>
      {modal ? 
      <MeetingModal 
       id = {id}
       closeModal = {()=>{setModal(false);}}
      ></MeetingModal>:null}
    </>
  );
};

export default Intervention;
