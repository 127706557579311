
import React from "react";

const ColoredLine = (props: any) => {

    const height: any = "30px";

    const total: any = 0;

    return (
        <>
            <div className="d-flex " style={{ width: "100%" }}>
                <div  style={{ height: "20px", width: "40px", marginLeft: `calc(${props.growthPercentile}% - 20px)` }}  > {props.text}</div>
                

            </div>
            <div className="d-flex " style={{ width: "100%" }}>
               
                <img src="assets/images/down.png" style={{ height: "40px", width: "20px", marginLeft: `calc(${props.growthPercentile}% - 20px)` }} alt="" />

            </div>
            <div className="d-flex ">

                <div
                    className="d-flex justify-content-center"
                    style={{
                        color: "red",
                        backgroundColor: "red",
                        height: `${height}`,
                        width: "20%"
                    }}
                >

                </div>
                <div
                    style={{
                        color: "#f2712b",
                        backgroundColor: "orange",
                        height: `${height}`,
                        width: "20%"
                    }}
                > </div>
                <div
                    style={{
                        color: "#e8a929",
                        backgroundColor: "#f9c412",
                        height: `${height}`,
                        width: "20%"
                    }}
                > </div>
                <div
                    style={{
                        color: "#57b229",
                        backgroundColor: "#f5ee1c",
                        height: `${height}`,
                        width: "20%"
                    }}
                > </div>
                <div
                    style={{
                        color: "#157dd3",
                        backgroundColor: "#b4d232",
                        height: `${height}`,
                        width: "20%"
                    }}
                > </div>

            </div>

            <div className="d-flex " style={{ width: "100%" }}>
                <img src="assets/images/toparrow.png" style={{ height: "40px", width: "20px", marginLeft: " calc(50% - 10px)" }} alt="" />


            </div>
            <div className="d-flex " style={{ width: "100%" }}>
                <div style={{ height: "40px", marginLeft: " calc(50% - 20px)" }}  >Average :50%</div>


            </div>


        </>
    );
}

export default ColoredLine;