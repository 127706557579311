import React from 'react';


const style = {
  grid: {
    margin: '0 -15px !important',
    width: 'unset'
  }
};

function GridContainer(props: any) {
  const { classes, children, ...rest } = props;
  return (
    <div container={true} {...rest} className="row">
      {children}
    </div>
  );
}

export default GridContainer;
