import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { url } from '../../store/initialStatus';
import Swal from 'sweetalert2';
import { Translate } from "../../helpers/Translate";
import Select from 'react-select';

import {
    Modal,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Spinner,
    ModalHeader,
} from "reactstrap";
import PayInstallment from '../onlinepayment/PayInstallment';
import MDBDataTableV5 from '../CustomDataTable';
import { PrintInvoice } from '../../helpers/func';
import PayService from '../onlinepayment/PayService';
import { NavLink } from 'react-router-dom';

const t = new Translate();


// 1000.1999999 -> 1,000.20
const format_number = (number: any) => {
    return Number(((number ?? 0) - 0).toFixed(2)).toLocaleString();
}
const all_option = { value: "all", label: t.translate("All") };
const servicenames_default = "all";
const students_default = "all";
const patment_status_default = "Overdue";
const patment_status_options = [
    all_option,
    { value: "paid", label: t.translate("Paid") },
    { value: "not yet", label: t.translate("Not Yet") },
    { value: "Overdue", label: t.translate("Overdue") },
]

const ServiceSubscription = () => {
    const [Error, setError] = useState({
        isError: false,
        message: "",
    })
    const [services, setServices] = useState([]);
    const [studentServices, setStudentsServices] = useState([]);
    const [studentAssignedServices, setStudentAssignedServices] = useState([]);
    const [isModalOpen, ShowModal] = useState<boolean>(false);
    const guardian = useSelector((state: any) => state.Parent.guardian);
    // console.log("guardian");
    // console.log(guardian);


    const [hasLoaded, setHasLoaded] = useState(false);



    const [students, setStudents] = useState<Array<any>>([]);
    const [selectedStudents, setSelectedStudents] = useState<Array<any>>([]);

    const [servicenames, setServicenames] = useState<Array<any>>([]);

    const [servicenames_filter, setServicenames_filter] = useState(servicenames_default);



    const [students_filter, setStudents_filter] = useState(students_default);
    const [ModalHeaderTitle, SetModalHeaderTitle] = useState<any>(null);
    const [feesUpdated, setFeesUpdated] = useState(false);
    const [patment_status_filter, setPatment_status_filter] = useState(patment_status_default);
    const [Pay, SetPayment] = useState<any>(null);

    const [Amount, SetAmount] = useState<any>("0");
    const [selectedService, setSelectedService] = useState<any>(null);

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
        },
    };
    useEffect(() => {
        Swal?.showLoading();

        axios?.post(`${url}servicesubscriptions/getservicesubscriptions.php`, {
            // id: guardian?.id ?? "",
            guardian_id: guardian?.guardian_id ?? "",
        })?.then((res: any) => {
            // console.log("res");
            // console.log(res);
            const res_data_data = res?.data?.data;
            if (res_data_data) {
                if(res_data_data.services?.length >0){

                    let tempServices: any = [];
                    setStudentAssignedServices(res_data_data.student_assigned_services)
                    res_data_data.services?.forEach((service: any) => {
                        let findIndex = tempServices.findIndex((ser: any) => ser.service_id == service.service_id);
                        if (findIndex == -1)
                            tempServices.push(service)
                    });
                    setServices(tempServices);
                    setStudentsServices(res_data_data.services)
    
                    setHasLoaded(true);
                    Swal.close();
                }else {
                    setError({
                        isError: true,
                        message: t.translate("Currently, there are no additional services available for subscription"),
                    })
                    Swal.close();
                }
            } else {


                Swal.fire({
                    icon: "error",
                    title: t.translate("Try again later!"),
                    // title: "Added successfully!",
                    showConfirmButton: false,
                    showDenyButton: false,
                    cancelButtonText: t.translate("Ok"),
                    showCloseButton: false,
                    showCancelButton: true,
                    // showLoaderOnDeny: true
                });
            }
        })?.catch((error: any) => {
            Swal.close();
            setError({
                isError: true,
                message: t.translate(error.response.data.msg) ,
            })

        })
    }, [feesUpdated])

    if (!hasLoaded && !Error.isError) {
        return (
            <div>{t.translate("Loading")}</div>
        );
    } else if (Error.isError) {
        return (
            <div className='m-5 text-center' style={{ color: "red", fontSize: "20px" }}>{t.translate(Error.message)} 
            {Error.message.includes("installments") ? ( <div> <NavLink to={"/dashboard/schoolfees"} className="btn btn-primary mt-1" style={{fontSize:"20px"}}> Click here to pay </NavLink></div>) :""}
           </div>
        );
    }
    function paymentFinished(installment: any) {
        SetPayment(null);
        if (installment) {
            setFeesUpdated(!feesUpdated)

        }
    }
    const handleStudentSelect = (e: any) => {

        // Extract student_ids from array2
        const studentIdsInArray2 = e.map((item: any) => item.value);

        // Filter array1 using includes
        const filteredArray = students.filter((student: any) => studentIdsInArray2.includes(student.student_id));
        console.log(filteredArray)
        setSelectedStudents(filteredArray)
        let Amount = filteredArray.reduce((sum, student) => +sum + +student.fees, 0)
        SetAmount(Amount)



    }
    if (Pay !== null) {
        return (
            <div
                style={{
                    zIndex: 100,
                    display: "block",
                    background: "white",
                    border: "0px none transparent",
                    overflow: "hidden auto",
                    visibility: "visible",
                    margin: "0px",
                    padding: "0px",
                    position: "fixed",
                    left: "0px",
                    top: "0px",
                    width: "100%",
                    height: "100%",
                }}
            >
                {Pay}
            </div>
        );
    } else
        return (
            <div>

                <div className=' row ' >
                    {
                        services?.map((availableService: any) => {
                            let ifAllSubscribed = studentAssignedServices?.filter((s: any) => s.service_id == availableService.service_id  );
                            let ifThereIsStudentSubscribed = studentAssignedServices?.find((s: any) => s.service_id == availableService.service_id  );
                            if(!ifThereIsStudentSubscribed && availableService.suspended == 1  ) return "";
                            return (
                                <div className="col-12 col-md-6 col-xl-4 d-flex justify-content-center align-items-baseline " key={availableService.id}>
                                    <div
                                        className="card shadow-sm p-3 mb-5 bg-white"
                                        style={{ width: "50rem", borderRadius: "16px" }}
                                    >
                                      


                                        <div className="card-body p-0">
                                            {availableService.suspended == 1 ? (<h5 className='text-end p-0'> <span className="badge badge-danger p-2 mx-2">{t.translate("This service is currently suspended.")}</span></h5>) :""}
                                            
                                            <h5 className="card-title fw-bolder">{t.translate(availableService.servicename ?? '')}</h5>

                                        </div>
                                        <ul className="list-group list-group-flush">

                                            <li className="list-group-item">{t.translate("Description")} : {availableService.description ?? ""}</li>
                                            <li className="list-group-item">{t.translate("Start date")} : {availableService.startdate ?? ""}</li>

                                            {
                                                studentServices?.filter((s: any) => s.service_id == availableService.service_id)?.map((studentService: any) => {
                                                    
                                                    let ifStudentAssigned = studentAssignedServices?.find((s: any) => s.service_id == studentService.service_id && s.student_id == studentService.student_id )
                                                    console.log(ifStudentAssigned)
                                                    
                                                    return (
                                                        <li className="list-group-item">Price for {studentService.fullname} : {studentService.fees} EGP{ ifStudentAssigned? (<span className="badge badge-danger p-2 mx-2">Subscribed</span>)  :""}</li>

                                                    )
                                                })
                                            }


                                        </ul>
                                        {ifAllSubscribed.length !=  studentServices?.filter((s: any) => s.service_id == availableService.service_id).length && availableService.suspended == 0 ?(
                                        <div className="card-body d-flex flex-column gap-3">
                                            <button className="btn btn-primary"
                                                onClick={() => {
                                                    let tempStudents: any = [];
                                                    studentServices?.filter((student_service: any) => student_service.service_id == availableService.service_id ).forEach((student: any) => {
                                                        let ifStudentAssigned = studentAssignedServices.find((student_assigned_service: any) => student_assigned_service.student_id == student.student_id && student_assigned_service.service_id == student.service_id)
                                                        
                                                        let findIndex = tempStudents.findIndex((ser: any) => ser.student_id == student.student_id);
                                                        if (findIndex == -1 && !ifStudentAssigned)
                                                            tempStudents.push(student)
                                                    });
                                                    if (tempStudents.length == 0) {Swal.fire("All students are subscribed","","warning");return}
                                                    setStudents(tempStudents)
                                                 
                                                    SetAmount(0)
                                                    setSelectedService(availableService)
                                                    ShowModal(true);
                                                }}
                                            >{t.translate("Subscribe")}</button>
                                        </div>) :""}
                                        



                                    </div>
                                </div>
                            );
                        })
                    }
                </div>



                {/* -------------------------------------------------------------- */}

                {/* credits */}



                {/* -------------------------------------------------------------- */}
                {/*  Payment modal  */}
                <Modal
                    isOpen={isModalOpen}
                    onAfterOpen={() => { }}
                    onRequestClose={() => { }}
                    contentLabel="Example Modal"
                >
                    <ModalHeader >
                    <div className="text-center" style={{ width: "100%" }}  >
                        <h4>   Service : {selectedService?.servicename}</h4>  
                    </div>
                    </ModalHeader>

                    <div className='row col-md-6   mt-3 pr-0'>
                        <label>{t.translate("Choose Students to Subscribe to the Service")}</label>
                        <Select
                            isMulti
                            closeMenuOnSelect={true}
                            options={students.map((s: any) => {
                                return { value: s.student_id, label: s.fullname }
                            })}
                            onChange={(e) => {
                                handleStudentSelect(e)
                            }}
                        // value={academicYearOptions.find((f: any) => { return f.value == yearId })}
                        />
                        <ul className="list-group list-group-flush">
                            {
                                selectedStudents?.map((studentService: any) => {
                                    return (
                                        <li className="list-group-item">Price for {studentService.fullname} : {studentService.fees} EGP</li>

                                    )
                                })
                            }


                        </ul>
                    </div>

                    <div className="row mt-3 col-12">
                        <div className="col-12">
                            <div className="form-group row">
                                <p className="col-12">
                                    {" "}
                                    {t.translate("Total Amount.")}{" "}
                                </p>
                                <div className="col-md-6 col-11">
                                    <input
                                        disabled
                                        className="form-control "
                                        value={Amount}
                                        type="number"

                                    />
                                </div>
                                <div className='col-1'>

                                    <p className="mt-2"> {t.translate("EGP.")} </p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <ModalFooter
                        style={{ display: "flex", justifyContent: "space-evenly" }}
                    >
                        <button
                            disabled={Amount < 1 ? true : false}
                            className="btn btn-primary"
                            onClick={() => {

                                if (Amount > 0) {
                                    SetModalHeaderTitle(t.translate("Initiating the Payment..."));
                                    SetPayment(
                                        <PayService
                                            amount={Amount}
                                            installment={selectedService}
                                            students = {selectedStudents}
                                            // std={SelectedStd}
                                            code="EW"
                                            onFinish={paymentFinished}
                                        />
                                    );
                                    ShowModal(false);
                                } else {
                                    // MySwal.fire({
                                    //   title: <p>Error!</p>,
                                    //   footer: "Copyright 2021",
                                    //   html: (
                                    //     <i>{t.translate("Please Enter a valid amount!")}</i>
                                    //   ),
                                    //   icon: "error",
                                    //   didOpen: () => {
                                    //     // `MySwal` is a subclass of `Swal`
                                    //     //   with all the same instance & static methods
                                    //     //MySwal.clickConfirm()
                                    //   },
                                    // });
                                }
                            }}
                        >
                            {t.translate("Continue")}
                        </button>

                        <button
                            className="btn btn-danger"
                            onClick={() => {ShowModal(false);
                                setSelectedStudents([])
                            }}
                        >
                            {t.translate("Cancel")}
                        </button>
                    </ModalFooter>
                </Modal>
            </div>



        )
}

export default ServiceSubscription