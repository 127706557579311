import React from "react";
import "./PanelHeader.css";

function PanelHeader(props:any) {
  return (
    <div
      className={
        "panel-header " +
        (props.size !== undefined ? "panel-header-" + props.size : "")
      }
    >
      {props.content}
    </div>
  );
}

export default PanelHeader;