import React from 'react';


import { Row, Col, Card, CardImg, CardBody, CardTitle } from 'reactstrap';
import { NavLink } from 'react-router-dom';
//import PanelHeader from '../../../';
import "../../Parent/parentpages/Home/Home.css";
import img12 from '../../../assets/reserved.png';
import img2 from '../../../assets/cafeteriamenu.png';
import { Translate } from '../../../helpers/Translate';


const Cafeteria = () => {
  const height = "100Px";
  const t = new Translate()

  return (
    <>

      <div className="content">

        <Row>
          <Col lg={2} md={3} sm={4} xs={6}>
            <NavLink to={"/dashboard/resturant"} >
              <Card className="frame text-center">
                <br />
                <CardImg top height={height} src={img12} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    {t.translate("Meals Reservations")}
                  </CardTitle>
                </CardBody>
              </Card>
            </NavLink>
          </Col>
          <Col lg={2} md={3} sm={4} xs={6}>
            <NavLink to={"/dashboard/cafeteriamenu"} >
              <Card className="frame text-center">
                <br />
                <CardImg top height={height} src={img2} alt="Card image cap" />
                <CardBody>
                  <CardTitle>

                    {t.translate("Cafeteria")}
                    {t.translate("Menu")}

                  </CardTitle>
                </CardBody>
              </Card>
            </NavLink>
          </Col>

        </Row>
        <br />
      </div>
    </>
  );
};

export default Cafeteria;