
import React from "react";

const ColoredLine = (props: any) => {
    const calculateReinforcements = () => {
        let total = 0;
        for (let index = 0; index < props.Reinforcements.length; index++) {

            total += +props.Reinforcements[index].points
        }
        return total;
    }

    const total = calculateReinforcements();

    return (
        <>
          <div className="d-flex ">
                <div className="d-flex justify-content-center" style={{ height: "40px", width: "25%" }}>
                    {total <= -3 ? (<img src="assets/images/-3.png" style={{ height: "40px", width: "50px" }} alt="" />) : null}
                </div>
                <div className="d-flex justify-content-center" style={{ height: "40px", width: "25%" }}>
                    {total == -2 ? (<img src="assets/images/-2.png" style={{ height: "40px", width: "50px" }} alt="" />) : null}
                </div>
                <div className="d-flex justify-content-center" style={{ height: "40px", width: "25%" }}>
                    {total == -1 ? (<img src="assets/images/-1.png" style={{ height: "40px", width: "50px" }} alt="" />) : null}
                </div>
                <div className="d-flex justify-content-center" style={{ height: "40px", width: "25%" }}>
                    {total == 0 ? (<img src="assets/images/0.png" style={{ height: "40px", width: "50px" }} alt="" />) : null}
                </div>
                <div className="d-flex justify-content-center" style={{ height: "40px", width: "25%" }}>
                    {total == 1 ? (<img src="assets/images/1.png" style={{ height: "40px", width: "50px" }} alt="" />) : null}
                </div>
                <div className="d-flex justify-content-center" style={{ height: "40px", width: "25%" }}>
                    {total == 2 ? (<img src="assets/images/2.png" style={{ height: "40px", width: "50px" }} alt="" />) : null}
                </div>
                <div className="d-flex justify-content-center" style={{ height: "40px", width: "25%" }}>
                    {total >= 3 ? (<img src="assets/images/3.png" style={{ height: "40px", width: "50px" }} alt="" />) : null}
                </div>

            </div>
            <div className="d-flex ">

                <div
                    className="d-flex justify-content-center"
                    style={{
                        color: "red",
                        backgroundColor: "red",
                        height: "20px",
                        width: "25%"
                    }}
                >

                </div>
                <div
                    style={{
                        color: "orange",
                        backgroundColor: "orange",
                        height: "20px",
                        width: "25%"
                    }}
                > </div>
                <div
                    style={{
                        color: "#f9c412",
                        backgroundColor: "#f9c412",
                        height: "20px",
                        width: "25%"
                    }}
                > </div>
                <div
                    style={{
                        color: "#f5ee1c",
                        backgroundColor: "#f5ee1c",
                        height: "20px",
                        width: "25%"
                    }}
                > </div>
                <div
                    style={{
                        color: "#b4d232",
                        backgroundColor: "#b4d232",
                        height: "20px",
                        width: "25%"
                    }}
                > </div>
                <div
                    style={{
                        color: "#84c341",
                        backgroundColor: "#84c341",
                        height: "20px",
                        width: "25%"
                    }}
                > </div>
                <div
                    style={{
                        color: "#33a949",
                        backgroundColor: "#33a949",
                        height: "20px",
                        width: "25%"
                    }}
                > </div>
            </div>

          
        </>
    );
}

export default ColoredLine;