import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Initial from './initialStatus';
import { getCookie, Request } from '../helpers/func';
import { isNullOrUndefined } from '../helpers/func';

interface UserState {
    token: string;
    ApiKey: string;
    UserId: number;
    UserName: string;
    PasswordHash: string;
    Name: string;
    Email: string;
    EmailConfirmed: boolean;
    PhoneNumber: string;
    RoleID: number;
    MenuItem: any[]; // If you have a specific type for MenuItem, replace `any` with that
}
const initialState:UserState = {
    token: '',
    ApiKey: '',
    UserId: 0,
    UserName: '',
    PasswordHash: '',
    Name: '',
    Email: '',
    EmailConfirmed: false,
    PhoneNumber: '',
    RoleID: 0,
    MenuItem: [],
};

// -----------------
// Async Action Creators - These are functions that perform async logic and dispatch actions.

export const GET_USER:any = createAsyncThunk(
    'user/getUser',
    async (_, { dispatch }) => {
        const x = getCookie('ucookie');
        if (!isNullOrUndefined(x) && x !== "") {
            const response = await Request(Initial.url + '/api/getuser', 'POST', x, '');
            const jdata = await response.json();
            if (response.status === 200) {
                jdata.Sid = x;
                dispatch(userSlice.actions.setUserLogin(jdata));
                return true;
            } else {
                return false;
            }
        }
        return false;
    }
);

// -----------------
// Slice - This function combines reducers and actions.

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserLogin: (state:UserState, action) => {
            state=action.payload
            return action.payload;
        },
        CLEAR_USER_LOGOUT: (state:UserState, action) => {
            state=initialState
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GET_USER.fulfilled, (state, action) => {
                return state;
            });
    },
});

/* The action creators will be automatically generated based on the reducer key names in createSlice */
export const { setUserLogin, CLEAR_USER_LOGOUT } = userSlice.actions;
export const actionCreators={
    GET_USER:GET_USER,
    setUserLogin,
    CLEAR_USER_LOGOUT:CLEAR_USER_LOGOUT
}

export default userSlice.reducer;