import React from "react";
import SignaturePad from "react-signature-canvas";
import { Translate } from "../../helpers/Translate";

function Signature(props: any) {
  let t = new Translate();
  return (
    <div>
      <div style={{ border: "2px solid", borderColor: "black" }}>
        <SignaturePad
          canvasProps={{ width: 570, height: 200, className: "sigCanvas" }}
          ref={props.reference}
          onEnd={props.onEnd}
          onBegin={props.onBegin}
          clearOnResize
        />
        ,
      </div>
      <button
        style={{ marginLeft: "40%" }}
        className="btn btn-danger"
        onClick={(e) => props.clear(e)}
      >
        {t.translate("Clear Pad")}
      </button>
    </div>
  );
}

export default Signature;
