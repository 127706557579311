import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { url } from '../../store/initialStatus';
import Swal from 'sweetalert2';
import { Translate } from "../../helpers/Translate";
import Select from 'react-select';

import {
    Modal,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Spinner,
    ModalHeader,
} from "reactstrap";
import PayInstallment from '../onlinepayment/PayInstallment';
import MDBDataTableV5  from '../CustomDataTable';
import { PrintInvoice } from '../../helpers/func';

const t = new Translate();

// 1000.1999999 -> 1,000.20
const format_number = (number: any) => {
    return Number(((number ?? 0) - 0).toFixed(2)).toLocaleString();
}
const all_option = { value: "all", label: t.translate("All") };
const servicenames_default = "all";
const students_default = "all";
const patment_status_default = "Overdue";
const patment_status_options = [
    all_option,
    { value: "paid", label: t.translate("Paid") },
    { value: "not yet", label: t.translate("Not Yet") },
    { value: "Overdue", label: t.translate("Overdue") },
]

const defaultTable = {
    columns: [
        {
            label: "#",
            field: "id",
        },
        {
            label: t.translate("Date"),
            field: "date",
        },
        {
            label: t.translate("Parent Account Number"),
            field: "account_no",
        },
        {
            label: t.translate("Amount"),
            field: "amount",
        },
        {
            label: t.translate("Parent Name"),
            field: "parentname",
        },
        {
            label: t.translate("Details"),
            field: "details",
        },
        {
            label: t.translate("Academic Year"),
            field: "year",
        },
        {
            label: t.translate("Notes"),
            field: "notes",
        },
        // {
        //     label: t.translate("User Name"),
        //     field: "displayname",
        // },
        {
            label: t.translate("Print"),
            field: "print",
        },
    ],
    rows: [
        {
            id: <Spinner color="info" />,
            date: <Spinner color="info" />,
            account_no: <Spinner color="info" />,
            amount: <Spinner color="info" />,
            parentname: <Spinner color="info" />,
            details: <Spinner color="info" />,
            year: <Spinner color="info" />,
            notes: <Spinner color="info" />,
            displayname: <Spinner color="info" />,
            print: <></>,
        },
    ],
}
const Schoolfees = () => {

    const [isModalOpen, ShowModal] = useState<boolean>(false);
    const guardian = useSelector((state: any) => state.Parent.guardian);
    // console.log("guardian");
    // console.log(guardian);

    const [hasLoaded, setHasLoaded] = useState(false);

    const [fees, setFees] = useState<Array<any>>([]);

    const [students, setStudents] = useState<Array<any>>([]);

    const [servicenames, setServicenames] = useState<Array<any>>([]);

    const [servicenames_filter, setServicenames_filter] = useState(servicenames_default);

    // const [credits, setCredits] = useState<Array<any>>([]);
    const [intDataTable, setIntDataTable] = useState(defaultTable);

    const [students_filter, setStudents_filter] = useState(students_default);
    const [ModalHeaderTitle, SetModalHeaderTitle] = useState<any>(null);
    const [feesUpdated, setFeesUpdated] = useState(false);
    const [patment_status_filter, setPatment_status_filter] = useState(patment_status_default);
    const [Pay, SetPayment] = useState<any>(null);

    const [Amount, SetAmount] = useState<any>("0");
    const [selectedInstallment, setSelectedInstallment] = useState<any>(null);

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
        },
    };
    useEffect(() => {
        Swal?.showLoading();
        axios?.post(`${url}schoolfees/getschoolfees.php`, {
            // id: guardian?.id ?? "",
            guardian_id: guardian?.guardian_id ?? "",
        })?.then((res: any) => {
            // console.log("res");
            // console.log(res);
            const res_data_data = res?.data?.data;
            if (res_data_data) {
                if (res_data_data?.hasOwnProperty("accountinstallments")) {
                    // setFees(res_data_data["accountinstallments"]);
                    const accountinstallments = res_data_data["accountinstallments"] ?? [];
                    if (accountinstallments) {
                        const Date_now = Date.now();
                        const accountinstallments_new = accountinstallments?.map((accountinstallment: any) => {
                            const new_accountinstallment = { ...accountinstallment }
                            if ("not yet" === accountinstallment.status) {
                                const overdue = Date_now > new Date(accountinstallment.date).getTime();
                                if (overdue) {
                                    new_accountinstallment.status = "Overdue";
                                }
                            }
                            return new_accountinstallment;
                        });
                        setFees(accountinstallments_new);
                    }
                }
                if (res_data_data?.hasOwnProperty("students")) {
                    setStudents(res_data_data["students"]);
                }
                if (res_data_data?.hasOwnProperty("credits")) {
                    // setCredits(res_data_data["credits"]);
                    const newRows = res_data_data["credits"]?.map((credit: any) => {
                        return {
                            ...credit,
                            print: (
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        PrintInvoice(credit?.account_no ?? "", credit?.id ?? "", (("28" === (credit?.service_id ?? '')) || ("39" === (credit?.service_id ?? '')) || ("50" === (credit?.service_id ?? ''))))
                                    }}
                                >
                                    {t.translate("Print")}
                                </button>
                            ),
                        };
                    })
                    setIntDataTable({ ...defaultTable, rows: newRows });
                }
                if (res_data_data?.hasOwnProperty("servicenames")) {
                    setServicenames(res_data_data["servicenames"]?.map((servicename: any) => ({ value: servicename.value, label: t.translate(servicename.label) })));
                }
                setHasLoaded(true);
                Swal.close();
            } else {

                Swal.fire({
                    icon: "error",
                    title: t.translate("Try again later!"),
                    // title: "Added successfully!",
                    showConfirmButton: false,
                    showDenyButton: false,
                    cancelButtonText: t.translate("Ok"),
                    showCloseButton: false,
                    showCancelButton: true,
                    // showLoaderOnDeny: true
                });
            }
        })?.catch((error: any) => {
            console.log("error");
            console.log(error);
            Swal.fire({
                icon: "error",
                title: t.translate("Try again later!"),
                // title: "Added successfully!",
                showConfirmButton: false,
                showDenyButton: false,
                cancelButtonText: t.translate("Ok"),
                showCloseButton: false,
                showCancelButton: true,
                // showLoaderOnDeny: true
            });
        })
    }, [feesUpdated])

    if (!hasLoaded) {
        return (
            <div>{t.translate("Loading")}</div>
        );
    }
    function paymentFinished(installment: any) {
        SetPayment(null);
        if (installment) {
            setFeesUpdated(!feesUpdated)

        }
    }
    if (Pay !== null) {
        return (
            <div
                style={{
                    zIndex: 100,
                    display: "block",
                    background: "white",
                    border: "0px none transparent",
                    overflow: "hidden auto",
                    visibility: "visible",
                    margin: "0px",
                    padding: "0px",
                    position: "fixed",
                    left: "0px",
                    top: "0px",
                    width: "100%",
                    height: "100%",
                }}
            >
                {Pay}
            </div>
        );
    } else
        return (
            <div>
                <div className='mb-4' >
                    <div className='card shadow-sm p-3  bg-white' >
                        {/* title */}
                        <div className="card-body row align-items-center ">
                            <h3 className="col-12" style={{color:"blue"}}>
                                {t.translate("School installments")}
                            </h3>
                        </div>
                        <div className="card-body row align-items-center ">
                            <h4 className="col-12">
                                {t.translate("Filters")}
                            </h4>
                        </div>
                        {/* fliter names */}
                        <div className="card-body row align-items-center ">
                            {/* students */}
                            <div className="  col-12 col-md-4 col-sm-6 mb-5 ">
                                <div className="row">
                                    <div className="col-12 mb-2">
                                        {t.translate("Student")}
                                    </div>
                                    <div className="col-12">
                                        <Select
                                            value={[all_option, ...students]?.find((x) => students_filter === x.value)}
                                            options={[all_option, ...students]}
                                            onChange={(e: any) => {
                                                const val = e?.value;
                                                if (val) {
                                                    setStudents_filter(val);
                                                }
                                            }}
                                            styles={{
                                                menuPortal: base => ({ ...base, zIndex: 9999999 }),
                                            }}
                                            menuPortalTarget={document.body}
                                            menuPosition='fixed'
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* servicenames */}
                            <div className="  col-12 col-md-4 col-sm-6 mb-5 ">
                                <div className="row">
                                    <div className="col-12 mb-2">
                                        {t.translate("Service")}
                                    </div>
                                    <div className="col-12">
                                        <Select
                                            value={[all_option, ...servicenames]?.find((x) => servicenames_filter === x.value)}
                                            options={[all_option, ...servicenames]}
                                            onChange={(e: any) => {
                                                const val = e?.value;
                                                if (val) {
                                                    setServicenames_filter(val);
                                                }
                                            }}
                                            styles={{
                                                menuPortal: base => ({ ...base, zIndex: 9999999 }),
                                            }}
                                            menuPortalTarget={document.body}
                                            menuPosition='fixed'
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Installment Status */}
                            <div className="  col-12 col-md-4 col-sm-12 mb-5 ">
                                <div className="row">
                                    <div className="col-12 mb-2">
                                        {t.translate("Installment Status")}
                                    </div>
                                    <div className="col-12">
                                        <Select
                                            value={patment_status_options?.find((x) => patment_status_filter === x.value)}
                                            options={patment_status_options}
                                            onChange={(e: any) => {
                                                const val = e?.value;
                                                if (val) {
                                                    setPatment_status_filter(val);
                                                }
                                            }}
                                            styles={{
                                                menuPortal: base => ({ ...base, zIndex: 9999999 }),
                                            }}
                                            menuPortalTarget={document.body}
                                            menuPosition='fixed'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className=' row ' >
                    {
                        fees?.filter((fee: any) => {
                            let students_flag = false;
                            if ("all" === students_filter) {
                                students_flag = true;
                            } else {
                                students_flag = (students_filter === fee.student_id);
                            }

                            let servicenames_flag = false;
                            if ("all" === servicenames_filter) {
                                servicenames_flag = true;
                            } else {
                                servicenames_flag = (servicenames_filter === fee.service_id);
                            }

                            let patment_status_flag = false;
                            if ("all" === patment_status_filter) {
                                patment_status_flag = true;
                            } else {
                                patment_status_flag = (patment_status_filter === fee.status);
                            }

                            return (patment_status_flag && students_flag && servicenames_flag);
                        })?.map((fee: any) => {
                            return (
                                <div className="col-12 col-md-6 col-xl-4 d-flex justify-content-center align-items-baseline " key={fee.id}>
                                    <div
                                        className="card shadow-sm p-3 mb-5 bg-white"
                                        style={{ width: "22rem", borderRadius: "16px" }}
                                    >
                                        <br />


                                        <div className="card-body">
                                            <h5 className="card-title fw-bolder">{t.translate(fee.servicename ?? '')}</h5>
                                            <h5 className="">{t.translate("Year")} : {fee.academicyear ?? new Date(fee.date ?? "")?.getFullYear() ?? ""}</h5>
                                        </div>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item" style={{ color: "blue" }} >{t.translate("Student ID")} : {fee.student_id ?? ""}</li>
                                            <li className="list-group-item fw-bolder" style={{ color: "blue" }} >{t.translate("Student Name")} : {fee.fullname ?? ""}</li>
                                            {/* <li className="list-group-item">{t.translate("Grade")} : {fee.grade}</li> */}
                                            <li className="list-group-item">{t.translate("Installment Status")} : {fee.status ?? ""}</li>
                                            <li className="list-group-item">{t.translate("Date")} : {fee.date ?? ""}</li>
                                            <li className="list-group-item">{t.translate("Total Amount")} : {format_number((((fee.amount ?? 0) - 0) + ((fee.paid ?? 0) - 0)) )}</li>
                                            <li className="list-group-item">{t.translate("Paid")} : {format_number(fee.paid ?? 0)}</li>
                                            <li className="list-group-item">{t.translate("Rest")} : {format_number(fee.amount ?? 0)}</li>
                                        </ul>

                                        {(() => {
                                            let icon = "clock";
                                            let background = "#848696";
                                            switch (fee.status) {
                                                case "paid":
                                                    icon = "check-circle";
                                                    background = "#1AC589";
                                                    break;
                                                case "Overdue":
                                                    icon = "times-circle";
                                                    background = "#DF2B1B";
                                                    break;

                                                default:
                                                    break;
                                            }
                                            let style = {
                                                alignItems: "center",
                                                justifyContent: "center",
                                                background: background,
                                                color: "white",
                                                borderRadius: "15px",
                                            }
                                            return (
                                                <div className="card-body d-flex flex-column gap-3" style={style} >
                                                    <div>
                                                        <i className={`fas fa-${icon}`}
                                                            style={{
                                                                fontSize: "100px",
                                                            }}
                                                        ></i>
                                                    </div>
                                                </div>
                                            )
                                        })()}

                                        {
                                            ("paid" === fee?.status) ?
                                                null
                                                :
                                                (
                                                    <div className="card-body d-flex flex-column gap-3">
                                                        <button className="btn btn-primary"
                                                            style={{
                                                                fontSize: "25px",
                                                            }}
                                                            onClick={() => {
                                                                SetAmount(fee.amount)
                                                                setSelectedInstallment(fee)
                                                                ShowModal(true);
                                                            }}
                                                        >{t.translate("Pay")}</button>
                                                    </div>
                                                )
                                        }

                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
                        </div>
                    </div>
                </div>
                



                {/* -------------------------------------------------------------- */}

                {/* credits */}
                <div className='card shadow-sm p-3  bg-white' >
                        {/* title */}
                        <div className="card-body row align-items-center ">
                            <h3 className="col-12" style={{color:"blue"}}>
                                {t.translate("Transactions")}
                            </h3>
                        </div>
                        <div className=' row ' >
                    <MDBDataTableV5
                        hover
                        responsive
                        borderless={false}
                        bordered
                        striped
                        
                        entriesOptions={[5, 20, 25]}
                        entries={5}
                        pagesAmount={4}
                        data={intDataTable}
                        fullPagination
                        searchTop
                        searchBottom={false}
                    />
                </div>
                </div>

               

                {/* -------------------------------------------------------------- */}
                {/*  Payment modal  */}
                <Modal
                    isOpen={isModalOpen}
                    onAfterOpen={() => { }}
                    onRequestClose={() => { }}
                    contentLabel="Example Modal"
                >
                   <ModalHeader >
                    <div className="text-center" style={{ width: "100%" }}  >
                        <h4>   {t.translate("Enter Amount")}</h4>  
                    </div>
                    </ModalHeader>
                    <div className="row col-12 mt-3">
                        <div className="col-12">
                            <div className="form-group row">
                                <p className="col-12">
                                    {" "}
                                    {t.translate("Please Enter the amount.")}{" "}
                                </p>
                                <div className="col-md-6 col-11">
                                    <input
                                        className="form-control "
                                        value={Amount}
                                        type="number"
                                        onChange={(e) => {
                                            SetAmount(e.target.value);
                                            console.log(Amount);
                                        }}
                                    />
                                </div>
                                <div className="col-1">
                                    <p className="mt-2"> {t.translate("EGP.")} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ModalFooter
                        style={{ display: "flex", justifyContent: "space-evenly" }}
                    >
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                              
                                if (Amount > 0) {
                                    SetModalHeaderTitle(t.translate("Initiating the Payment..."));
                                    SetPayment(
                                        <PayInstallment
                                            amount={Amount}
                                            installment={selectedInstallment}
                                            // std={SelectedStd}
                                            code="EW"
                                            onFinish={paymentFinished}
                                        />
                                    );
                                    ShowModal(false);
                                } else {
                                    // MySwal.fire({
                                    //   title: <p>Error!</p>,
                                    //   footer: "Copyright 2021",
                                    //   html: (
                                    //     <i>{t.translate("Please Enter a valid amount!")}</i>
                                    //   ),
                                    //   icon: "error",
                                    //   didOpen: () => {
                                    //     // `MySwal` is a subclass of `Swal`
                                    //     //   with all the same instance & static methods
                                    //     //MySwal.clickConfirm()
                                    //   },
                                    // });
                                }
                            }}
                        >
                            {t.translate("Continue")}
                        </button>

                        <button
                            className="btn btn-danger"
                            onClick={() => ShowModal(false)}
                        >
                            {t.translate("Cancel")}
                        </button>
                    </ModalFooter>
                </Modal>
            </div>



        )
}

export default Schoolfees