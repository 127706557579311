import { createSlice, createAsyncThunk, configureStore, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { requestWithAxios, parseJwt } from '../helpers/func';
import setAuthToken from '../helpers/setAuthToken';
import { url } from './initialStatus';
import * as Initial from './initialStatus';
import { Translate } from '../helpers/Translate';

// Define async thunks for asynchronous actions

export const UpdateParent:any = createAsyncThunk(
  'user/UpdateParent',
  async (data, { rejectWithValue }) => {
    try {
      const res = await requestWithAxios(`${url}guardian/updateguardian.php`, data);
      return { msg: res.data.msg, guardian: res.data.data };
    } catch (error) {
      return rejectWithValue('something went wrong');
    }
  }
);

export const ChangePassword:any = createAsyncThunk(
  'user/ChangePassword',
  async (data, { rejectWithValue }) => {
    try {
      const res = await requestWithAxios(`${url}guardian/updatepassword.php`, data);
      return { msg: res.data.msg };
    } catch (error:any) {
      return rejectWithValue(error.message);
    }
  }
);

export const GetParent:any = createAsyncThunk(
  'user/GetParent',
  async (id, { rejectWithValue }) => {
    try {
      const res = await requestWithAxios(`${url}guardian/getguardian.php`, { id });
      return res.data.data;
    } catch (error) {
      return rejectWithValue('something went wrong');
    }
  }
);

export const GetNotifications:any = createAsyncThunk(
  'user/GetNotifications',
  async (data, { rejectWithValue }) => {
    try {
      const res = await requestWithAxios(`${url}users/chatlistusers/getnotifications.php`, data);
      return res.data.data;
    } catch (error) {
      return rejectWithValue('failed to fetch notifications');
    }
  }
);

export const GetTransactions:any = createAsyncThunk(
  'user/GetTransactions',
  async (data, { rejectWithValue }) => {
    try {
      const res = await requestWithAxios(`${url}users/transaction/gettransactions.php`, data);
      return res.data.data;
    } catch (error) {
      return rejectWithValue('failed to fetch transactions');
    }
  }
);

export const GenerateCode:any = createAsyncThunk(
  'user/GenerateCode',
  async (data, { rejectWithValue }) => {
    try {
      const res = await requestWithAxios(`${url}guardian/generatecode.php`, data);
      return { msg: res.data.msg };
    } catch (error) {
      return rejectWithValue("Your Email is not Exists in our Database.<br> Please Make sure of it or, Contact us: <br> Phone:<a href='tel:+20 110 208 9000'>+20 110 208 9000</a><br>Email:<a href='mailto:ivyparent@ivyis.org'>IVYParent@ivyis.org</a>");
    }
  }
);

export const CheckCode:any = createAsyncThunk(
  'user/CheckCode',
  async (data, { rejectWithValue }) => {
    try {
      const res = await requestWithAxios(`${url}guardian/checkcode.php`, data);
      return { msg: res.data.msg };
    } catch (error) {
      return rejectWithValue("Verification code is not correct");
    }
  }
);

export const NewPassword:any = createAsyncThunk(
  'user/NewPassword',
  async (data, { rejectWithValue }) => {
    try {
      const res = await requestWithAxios(`${url}guardian/newpassword.php`, data);
      return { msg: res.data.msg };
    } catch (error:any) {
      return rejectWithValue(error.message);
    }
  }
);

export const UploadFile:any = createAsyncThunk(
  'user/UploadFile',
  async (data, { dispatch,rejectWithValue }) => {

    dispatch(startLoading())
    const res = await requestWithAxios(url + "guardian/upload.php", data);


  },
)

export const Login:any = createAsyncThunk(
  'user/Login',
  async (data, { rejectWithValue }) => {
    try {
      const res = await requestWithAxios(`${url}auth/login.php`, data);
      if (res.data.data === "error") {
        return rejectWithValue(res.data.msg);
      } else {
        const data = parseJwt(res.data.data.guardian_info.token);
        console.log(data);
        const expDate = new Date(new Date(data.exp * 1000));
        Cookies.set("guardiantoken", res.data.data.guardian_info.token, { sameSite: 'none', secure: true, expires: 3 });
        Cookies.set("guardiantranstoken", res.data.data.guardian_info.transToken, { sameSite: 'none', secure: true, expires: 3 });
        Cookies.set("guardian", JSON.stringify(res.data.data.guardian_info), { sameSite: 'none', secure: true, expires: 3 });
        setAuthToken(res.data.data.token);
        return {
          expDate,
          jwt: res.data.data.guardian_info.token,
          transjwt: res.data.data.guardian_info.transToken,
          guardian: res.data.data.guardian_info.guardian,
          schoologyUserName: res.data.data.guardian_info.schoologyUserName,
          schoologyPass: res.data.data.guardian_info.schoologyPass
        };
      }
    } catch (error) {
      return rejectWithValue('Failed to login');
    }
  }
);

export const LoginIframe = createAsyncThunk(
  'user/LoginIframe',
  async (_, { rejectWithValue }) => {
    try {
      const token = Cookies.get("guardiantoken");
      const transToken = Cookies.get("guardiantranstoken");
      const Gdata = JSON.parse(Cookies.get("guardian") ?? "");
      if (!token) {
        throw Error('Token not found');
      } else {
        const data = parseJwt(token);
        const expDate = new Date(new Date(data.exp * 1000));
        setAuthToken(token);
        return {
          expDate,
          jwt: token,
          ishold :Gdata.ishold ,
          transjwt: transToken,
          guardian: Gdata.guardian,
          schoologyUserName: Gdata.schoologyUserName,
          schoologyPass: Gdata.schoologyPass
        };
      }
    } catch (error) {
      return rejectWithValue('Failed to load iframe login');
    }
  }
);

export const FetchDictionary:any = createAsyncThunk(
  'user/FetchDictionary',
  async (_, { rejectWithValue }) => {
    try {
      let t = new Translate();
      let d = await t.getDictionary();
      return d
    } catch (error) {
      return rejectWithValue('Failed to fetch dictionary');
    }
  }
);

export const ReadNotification:any = createAsyncThunk(
  'user/ReadNotification',
  async (data, { rejectWithValue }) => {
    try {
      const res = await requestWithAxios(`${url}users/chatlistusers/readnoti.php`, data);
      return res.data.msg;
    } catch (error) {
      return rejectWithValue('Failed to mark notifications as read');
    }
  }
);

// Define the initial state using Initial status
const initialState = Initial.Parent;

// Create slice combining reducers and action creators
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearParent(state) {
      return {
        ...state,
        updateGuardianLoading: false,
        updateMsg: '',
        error: null,
        guardianLoading: false,
        loginerror: '',
        loginloading: false
      };
    },
    clearJwt(state) {
      state.jwt = '';
    },
    logout(state) {
      Cookies.remove('guardiantoken');
      Cookies.remove('guardian');
      Cookies.remove('guardiantranstoken');
      return {
        ...state,
        jwt: ''
      };
    },
    saveToken() {},
    startLoading(state) {
      state.guardianLoading= true
    },
    endLoading(state,action){
      const {res} = action.payload;
      state.guardianLoading= false
      state.guardian = res
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(UpdateParent.pending, (state) => {
        state.updateGuardianLoading = true;
      })
      .addCase(UpdateParent.fulfilled, (state, action) => {
        state.updateGuardianLoading = false;
        state.updateMsg = action.payload.msg;
        state.guardian = action.payload.guardian;
      })
      .addCase(UpdateParent.rejected, (state, action) => {
        state.updateGuardianLoading = false;
        state.error = action.payload;
      })
      .addCase(ChangePassword.pending, (state) => {
        state.updateGuardianLoading = true;
      })
      .addCase(ChangePassword.fulfilled, (state, action) => {
        state.updateGuardianLoading = false;
        state.updateMsg = action.payload.msg;
      })
      .addCase(ChangePassword.rejected, (state, action) => {
        state.updateGuardianLoading = false;
        state.error = action.payload;
      })
      .addCase(GetParent.pending, (state) => {
        state.guardianLoading = true;
      })
      .addCase(GetParent.fulfilled, (state, action) => {
        state.guardianLoading = false;
        state.guardian = action.payload;
      })
      .addCase(GetParent.rejected, (state, action) => {
        state.guardianLoading = false;
        state.error = action.payload;
      })
      .addCase(GetNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload;
      })
      .addCase(ReadNotification.fulfilled, (state, action) => {
        state.notifications = state.notifications.map((notification:any) => (
          { ...notification, read: true }
        ));
      })
      .addCase(GetTransactions.fulfilled, (state, action) => {
        state.transactions = action.payload;
      })
      .addCase(GenerateCode.pending, (state) => {
        state.updateGuardianLoading = true;
      })
      .addCase(GenerateCode.fulfilled, (state, action) => {
        state.updateGuardianLoading = false;
        state.updateMsg = action.payload.msg;
      })
      .addCase(GenerateCode.rejected, (state, action) => {
        state.updateGuardianLoading = false;
        state.error = action.payload;
      })
      .addCase(CheckCode.pending, (state) => {
        state.updateGuardianLoading = true;
      })
      .addCase(CheckCode.fulfilled, (state, action) => {
        state.updateGuardianLoading = false;
        state.updateMsg = action.payload.msg;
      })
      .addCase(CheckCode.rejected, (state, action) => {
        state.updateGuardianLoading = false;
        state.error = action.payload;
      })
      .addCase(NewPassword.pending, (state) => {
        state.updateGuardianLoading = true;
      })
      .addCase(NewPassword.fulfilled, (state, action) => {
        state.updateGuardianLoading = false;
        state.updateMsg = action.payload.msg;
      })
      .addCase(NewPassword.rejected, (state, action) => {
        state.updateGuardianLoading = false;
        state.error = action.payload;
      })
      .addCase(Login.pending, (state) => {
        state.loginloading = true;
        FetchDictionary();
      })
      .addCase(Login.fulfilled, (state, action) => {
        state.loginloading = false;
        state.jwt = action.payload.jwt;
        state.guardian = action.payload.guardian;
        state.schoologyPass = action.payload.schoologyPass;
        state.schoologyUserName = action.payload.schoologyUserName;
        state.transjwt = action.payload.transjwt;
      })
      .addCase(Login.rejected, (state, action) => {
        state.loginloading = false;
        state.loginerror = action.payload;
      })
      .addCase(LoginIframe.fulfilled, (state, action) => {
        state.jwt = action.payload.jwt;
        state.transjwt = action.payload.transjwt;
        state.guardian = action.payload.guardian;
        state.schoologyUserName = action.payload.schoologyUserName;
        state.schoologyPass = action.payload.schoologyPass;
      })
      .addCase(FetchDictionary.fulfilled, (state, action) => {
        state.dictionary_fetched = true;
      })
      .addCase(UploadFile.fulfilled, (state, action) => {

      });
  }
});

// Export actions and reducers
export const { clearParent, clearJwt, logout, saveToken,startLoading,endLoading } = userSlice.actions;

// Export all action creators
export const actionCreators = {
  UpdateParent,
  ChangePassword,
  GetParent,
  GetNotifications,
  ReadNotification,
  GetTransactions,
  GenerateCode,
  CheckCode,
  NewPassword,
  Login,
  LoginIframe,
  FetchDictionary,
  ClearParent:clearParent,
  ClearJwt:clearJwt,
  Logout:logout,
  SaveToken:saveToken,
  UploadFile
};


export default userSlice.reducer;