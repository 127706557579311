import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import MedicalRecord from './MedicalRecord'
import Chromebook from './ChromeBook';
import { Row, Col, Card, CardImg, CardBody, CardTitle, ModalBody, ModalFooter, Modal, ModalHeader, Form, Input, FormGroup } from 'reactstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import PanelHeader from '../PanelHeader/PanelHeader';
import "./Home.css";
import { Translate } from '../../../../helpers/Translate';
import axios from 'axios';
import Swal from 'sweetalert2';

//const img1 = require("./assets/img/schoology_logo.png").default;

import img12 from "./assets/img/menu.png"
import img2 from "./assets/img/ivy_transportation.png";

import wallet from "./assets/img/wallet.png";
import finance from "./assets/img/finance.jpg";
import { url } from '../../../../store/initialStatus';

const t = new Translate();




const Home = () => {

  const navigate = useNavigate();
  const height = "100Px";
  const transjwt: any = useSelector(
    (state: any) => state.Parent.transjwt
  );
  const Parent: any = useSelector(
    (state: any) => state.Parent
  );
  const schoologyUserName: any = useSelector(
    (state: any) => state.Parent.schoologyUserName
  );



  console.log(window.location.href.includes("schoolfees"))
  if(Parent.guardian.guardian_id != undefined ) {
  axios.get(`${url}auth/checkifhold.php?guardian_id=${Parent.guardian.guardian_id}`).then((res)=>{
    if(res.data.data == 1) {
   
      Swal.fire({
        title:t.translate("You have an overdue installment that needs to be paid."),
        html :t.translate("Please note that your account will remain on hold until all overdue installments are paid") ,
        
        showCancelButton :true,
        confirmButtonText: t.translate("Click here to pay"),
       
        icon: "warning",
      }).then((res)=>{
        if(res.isConfirmed){
          navigate({ pathname: "/dashboard/schoolfees" });
        }else{

        }

      })
    }
  }) }

  const setLms = () => {

    axios.post(`${process.env.REACT_APP_LMS_BASURL}include/store_parent_token.php`,
      {

        username: schoologyUserName,
        emaildomain: "ivyis.org"
      }).then((res) => {


        const url = "https://lms.ivyis.org";
        window.open(url);

      }).catch((err) => {
        console.log(err)
        Swal.fire(
          t.translate("An Error Occurred"),
          "Something wrong happened!",
          "error"
        );
      })
  }

  return (
    <>

      <MedicalRecord />

      <Chromebook />
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col lg={3} md={4} sm={6} xs={6}>
          <a className="" style={{ cursor: "pointer" }} target='_blank' href={`${process.env.REACT_APP_LMS_BASURL}include/store_parent_token.php?username=${schoologyUserName}&emaildomain=ivyis.org`}>

              <Card className="frame text-center p-2">
                <br />
                <CardImg top height={height} src={"https://staff.ivyis.org/images/ivylms.jpg"} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    {t.translate("IVY's LMS")}
                  </CardTitle>
                </CardBody>
              </Card>
            </a>
          </Col>
          <Col lg={3} md={4} sm={6} xs={6}>
            <NavLink to={"/dashboard/cafeteria"} >
              <Card className="frame text-center p-2">
                <br />
                <CardImg top height={height} src={img12} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    {t.translate("Cafeteria")}
                  </CardTitle>
                </CardBody>
              </Card>
            </NavLink>
          </Col>
          <Col lg={3} md={4} sm={6} xs={6}>
            <a href={`https://transportation.ivyis.org/?hash=` + transjwt} target="_blank" className="">
              <Card className="frame text-center p-2">
                <br />
                <CardImg top height={height} src={img2} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    {t.translate("Transportation")}
                  </CardTitle>
                </CardBody>
              </Card>
            </a>
          </Col>
          <Col lg={3} md={4} sm={6} xs={6}>
            <NavLink to={"/dashboard/finance"} >
              <Card className="frame text-center p-2">
                <br />
                <CardImg top height={height} src={finance} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    {t.translate("Smart Wallet")}
                  </CardTitle>
                </CardBody>
              </Card>
            </NavLink>
          </Col>
        </Row>
        <br />
      </div>
    </>
  );
};

export default Home;