import React, { useEffect, useState } from 'react';
// import { Line } from 'react-chartjs-2';
import { Chart} from 'chart.js';
// //import classes from './LineChart.module.css';
// Chart.register(...registerables);

import $ from "jquery"
function LineChart(props: any) {

 
   
  // const [labels, setLabels] = useState(["one" , "two" , "three" , "four"])
  // const [datasets, setDataSets] = useState([{
  //   label: 'My First Dataset',
  //   data: [1,2,3,4],
  //   fill: false,
  //   borderColor: 'rgb(75, 192, 192)',
  //   tension: 0.1
  // }]);
  // const Colors = ["red", "green", "yellow", "blue", "#eb7734", "#82f705", "black", "grey", "pink", "#05f7ef"]
  


  // const data = {
  //   labels: labels,
  //   datasets: datasets,
  // };


 

  const options: any = {

    scales: {
      y: {

        min: 0,
        max: 7,
        ticks: {
          stepSize: 1
        }
      }
    }
  }


 

 console.log(props.data)

  $(document).ready(()=>{

    new Chart(props.subject, {
      type: "line",
    data: {
      labels: props.labels,
      datasets: [{
        fill: false,
        backgroundColor: "rgba(0,0,255,1.0)",
        borderColor: "rgba(0,0,255,0.1)",
        data: props.data
      }]
    },
    options: {
      scales: {
        y: {
          type: 'timeseries',
          ticks: {
            stepSize: 10
          },
          min: +props.min -9, max:+props.max+9
        },
      }
    }
  });
})

  return(
    <div>
      <canvas id={props.subject} style={{width: "100%", maxWidth :"600px"}}></canvas>
    </div>
  )

 

}
export default LineChart;