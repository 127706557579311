import { Component } from "react";

import React from 'react';







class Payments extends Component<any,any> {
    constructor(props:any){
        super(props)
        this.state = {}
    }

    render(){
        return (
        
         <div> 
          <div className='justify-content-center align-items-center mt3'>
                  <p className="text-start text-blue " style={{fontSize: '1.2rem'}}>Payment History:</p>
                  <div className='table-responsive bg-light'>
                    <table
                      className='table-hover table-bordered text-center'
                      style={{ width: '100%' }}
                    >
                      <thead>
                        <tr>
                          <th scope='col'>#</th>
                          <th scope='col'>Date</th>
                          <th scope='col'>Payment amount</th>
                          <th scope='col'>Payment method</th>
                          <th scope='col'>Note</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>2021-05-16 10:09:10</td>
                          <td>300</td>
                          <td>Online payment</td>
                          <td>Outstanding payment</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                    <div className="justify-content-end d-flex font-weight-bold mt-3 "  style={{fontSize:"15px"}}>Total amount : 300 </div>
                </div>     
             
             
         </div>
            
            )
    }

}


export default Payments