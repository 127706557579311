import React, { Component } from "react";
import "./chatBody.css";
import ChatList from "../chatList/ChatList";
import ChatContent from "../chatContent/ChatContent";
import Nav from "../nav/Nav";
import "./chatBody.css";
import { Row , Col} from "reactstrap";


 class ChatBody extends Component<any,any> {


  constructor(props:any){
    super(props)
    this.state= {
      mid :""
    }
  }
  avatarClicked(){

  }
  render() {
    return (
  <div >
      <Nav />       
      <Row >  
      <div className="main__chatbody">
       
          <Col>
          
          {/* <Switch>
                    <Route exact path="/dashboard/chatbody/chatlist" 
                    component={()=> <ChatList />}></Route>
                    <Route exact path="/dashboard/chatbody/chatcontent" 
                    component={()=> <ChatContent />}></Route>
          </Switch> */}
      
       
       
        </Col>
        
       
      </div>
      </Row>
      
      </div>
    );
  }
}
export default ChatBody
