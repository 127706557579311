import React, { useEffect, useState } from "react";
import MDBDataTableV5 from "../../CustomDataTable";
import { Spinner, Card, CardBody, CardHeader } from "reactstrap";
import Swal from "sweetalert2";
import axios from "axios";
import { Link } from "react-router-dom";
import { Translate } from "../../../helpers/Translate";

const ClinicVisits: React.FC = () => {
  let t = new Translate();
  const [cvsDataTable, setCvsDataTable] = useState({
    columns: [
      {
        label: "#",
        field: "id",
      },
      {
        label: t.translate("Student"),
        field: "std",
      },
      {
        label: t.translate("Grade"),
        field: "grade",
      },
      {
        label: t.translate("Date"),
        field: "date",
      },
      {
        label: t.translate("Time"),
        field: "time",
      },
      {
        label: t.translate("View"),
        field: "view",
      },
    ],
    rows: [
      {
        id: <Spinner color="info" />,
        std: <Spinner color="info" />,
        grade: <Spinner color="info" />,
        date: <Spinner color="info" />,
        time: <Spinner color="info" />,
        view: <></>,
      },
    ],
  });

  useEffect(() => {
    try {
      axios
        .get(`${process.env.REACT_APP_BASEURL}student/getclinicvisits.php`)
        .then((res) => {
          console.log(res.data.data);
          setCvsDataTable((prevState) => {
            const newState = { ...prevState };
            let ser = 1;
            // change .exams below to .homeworks after backend finished
            let newRows = res.data.data.map((cv: any, key: any) => {
              return {
                ...cv,
                id: ser++,
                std: (
                  <p key={key}>
                    {cv.student_id} : {cv.fullname}
                  </p>
                ),
                grade: cv.grade,
                date: cv.date,
                time: cv.time,
                view: (
                  <Link
                    to={`/dashboard/clinicvisits/${cv.id}`}
                    className="btn btn-info"
                  >
                    {t.translate("View")}
                  </Link>
                ),
              };
            });
            return { ...newState, rows: newRows };
          });
        });
    } catch {
      Swal.fire("Error while grapping interventions!", "", "error");
    }
  }, []);

  return (
    <>
      <div className="row justify-content-center w-100 mx-0 my-5 linkssec">
        <Card>
          <CardHeader>{t.translate("Clinic Visits")}</CardHeader>
          <hr />
          <CardBody className="bg-light">
            <MDBDataTableV5
              hover
              responsive
              borderless={false}
              bordered
              striped
              entriesOptions={[5, 20, 25]}
              entries={5}
              pagesAmount={4}
              data={cvsDataTable}
              fullPagination
              searchTop
              searchBottom={false}
            />
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default ClinicVisits;
