import React, { Component, ErrorInfo, ReactNode } from 'react';

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<{ children: ReactNode }, ErrorBoundaryState> {
  state = {
    hasError: false
  };


  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    // Update state so the next render shows the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can also log the error to an error reporting service
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    window.addEventListener("unhandledrejection", function(event) {
        // Prevent the default handling (such as error log)
        event.preventDefault();
        
        console.error("Unhandled promise rejection:", event.promise, "reason:", event.reason);
        // Here you can implement logic to show user-friendly messages, logging etc.
    });
    if (this.state.hasError) {
      // Customize the fallback UI as per your need
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;