import React, { Component, createRef } from "react";

import "./chatContent.css";
import Avatar from "../chatList/Avatar";
import ChatItem from "./ChatItem";

import { connect } from "react-redux";

import { actionCreators as ChatCreator } from "../../../../store/Chat";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

const style = {
  height: 30,
  border: "1px solid green",
  margin: 6,
  padding: 8
};


const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
  GetMessages: (data: any) =>
  dispatch(ChatCreator.GetMessages(data)),
  SaveMsg: (data: any) =>
  dispatch(ChatCreator.SaveMsg(data)),
 
});

function mapStateToProps(state: any) {
  return {
    Messages: state.Chat.Messages,
    count: state.Chat.count,
    name: state.Chat.name,
    image: state.Chat.image,
    guardian: state.Parent.guardian,
    

  };
}

const connector=connect(mapStateToProps, mapDispatchToProps)

class ChatContent extends Component<any, any> {

  private messagesEndRef: React.RefObject<HTMLInputElement>;
 
  chatItms :any = [ ] ;
    

  constructor(props: any) {
    super(props);
    this.state = {
      chat: this.chatItms,
      msg: "",
      msg_from : this.props.guardian.mid ,
      msg_to :5 , 
      scroll:true,
     
      length : 10,       
      pageNumber : 1,
    
    };
    this.messagesEndRef = createRef();
  }

  
  fetchMoreData = () => {
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    let length = this.state.length +  10
    this.setState({length : length })

  };
  

  // scrollToBottom = () => {
  //   let current: any = this.messagesEndRef.current;
  //   current.scrollIntoView({behavior: "smooth"});
  // };


componentDidUpdate(previousprops:any, previousstate:any){
if(previousprops.Messages !== this.props.Messages){
  this.setState({chat: this.props.Messages})
}
}

 ineterval = setInterval(()=>{
  this.props.GetMessages({source : this.props.guardian.mid , dest : this.props.location.search.split("=")[1]  , length : this.state.length } )  

},3000)

  componentDidMount() {
  
    this.props.GetMessages({source : this.props.guardian.mid , dest : this.props.location.search.split("=")[1] ,  length : this.state.length} )  

     this.onKeyDown = this.onKeyDown.bind(this)
 
    
  }
  componentWillUnmount(){

    clearInterval(this.ineterval)
    
}

  onStateChange = (e: any) => {
    this.setState({ msg: e.target.value });
  };

  onKeyDown =(e:any)=>{
   // this.scrollToBottom();
      if (e.keyCode == 13) {
        if (this.state.msg != "") {
          let msg:any = {  id :Math.random(),msg_content : this.state.msg , msg_from : this.state.msg_from , msg_to : this.props.location.search.split("=")[1] }

          const formData = new FormData();
        
          for (const property in msg) {
    
            formData.append(
             property,
             msg[property],
            
             
            );
          }
          this.props.SaveMsg(formData)
          let chat = [...this.state.chat]
          chat.push({
            ...msg
             
          });
          this.setState({ chat: [...chat] });

          this.setState({ msg: "" });
          
        }
      }
  //  this.scrollToBottom();

    
  }

  addFileHandler = (e: any) => {

    const file: any = e.target.files[0];

    if (file && file.size > 0) {
      var reader: any = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
          
          let msg :any = {  id :Math.random(),msg_content :  file.name , msg_from : this.state.msg_from , msg_to : this.props.location.search.split("=")[1] , document :file }
    const formData = new FormData();
        
          for (const property in msg) {
    
            formData.append(
             property,
             msg[property],
            
             
            );
          }
          let chat = [...this.state.chat]
          chat.push({
            ...msg
            
          });
          this.props.SaveMsg(formData)
          this.setState({ chat: [...chat] });
          //this.scrollToBottom();
          this.setState({ msg: "" });
          // this.setState({ image: reader.result, document:  file });
        
      };
    }
  };
  openAddFileInputHandler = () => {
    const fileInput = document.getElementById("addFile") as HTMLInputElement;
    fileInput.click();
  };

  render() {
      
    return (
      <div className="main__chatcontent">
        <Link to="/dashboard/chatlist" 
        className="btn btn-primary linkmargin float-right" 
        type="button"><i className="fa fa-backward" aria-hidden="true"> </i></Link>
        <div className="content__header">
          <div className="blocks">
            <div className="current-chatting-user">
              <Avatar
                isOnline="active"
                image= {this.props.image}
              />
              <p>{this.props.name}</p>
            </div>
          </div>

         
        </div>
        <div className="content__body">
          
          <div className="chat__items">
          <div
                id="scrollableDiv"
                style={{
                  height: 300,
                  overflow: 'auto',
                  display: 'flex',
                  flexDirection: 'column-reverse',
                }}
              >
                <InfiniteScroll
                dataLength={this.state.chat.length}
                next={this.fetchMoreData}
                style={{ display: 'flex', flexDirection: 'column-reverse' }}
                hasMore={this.state.chat.length != this.props.count}
                loader={<h4> &nbsp; Loading...</h4>}
                inverse={true} //              
              scrollableTarget="scrollableDiv"
              >
                 {this.state.chat.map((itm:any, index:any) => (
            <ChatItem style={style} 
                  animationDelay={index + 2}
                  key={itm.id}
                  user={itm.msg_from != this.props.guardian.mid ? "other" : "me"}
                  msg={itm.type ==  "image" ? <img  src = {  itm.driveid?.includes("https://")?itm.driveid : `https://drive.google.com/uc?id=${itm.driveid}&export=download`}/>
                   : itm.type=="file" ? <a className="text-white" href={ itm.driveid?.includes("https://")?itm.driveid :`https://drive.google.com/uc?id=${itm.driveid}&export=download`}> {itm.documentname}</a> : itm.msg_content}
                  image={ itm.msg_from != this.props.guardian.mid ? this.props.driveid : `https://drive.google.com/uc?id=${this.props.guardian.driveid}&export=download`    }
                     time = {itm.time}
                  />
          ))}
                
                  </InfiniteScroll>
                  </div>
            <div ref={this.messagesEndRef} />
            
          </div>
        </div>
      
        <div className="content__footer" 
        >
          
          <input
                    type="file"
                    id="addFile"
                    hidden={true}
                    onChange={this.addFileHandler}
                  />
          <textarea
             className="inputmargin"
            
              placeholder="  Type a message here"
              onChange={this.onStateChange}
              value={this.state.msg}   
              onKeyDown ={this.onKeyDown}           
            /> 
            
            
           &nbsp;&nbsp;
           
          <label className="labelupload" style={{cursor:"pointer"}} > <i className="fa fa-upload"  aria-hidden="true" 
          onClick={this.openAddFileInputHandler}></i></label>
    
        
        </div>
      </div>
    );
  }
}



export default connector(ChatContent);