import { Component } from "react";
import React from 'react';
import {Hourglass as Loader} from "react-loader-spinner";
import { actionCreators as AssessmentActionCreator } from "../../../store/Parent";

import axios from "axios";
import { connect } from "react-redux";

const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
  UploadFile: (data: any) =>
    dispatch(AssessmentActionCreator.UploadFile(data)),

});

class DoPayment extends Component<any, any> {
    constructor(props: any) {
        super(props)
        this.state = { loading :false , paymentSelect :"", document :null , test : "test1"}
    }

    openAddFileInputHandler = () => {
      const fileInput = document.getElementById("addFile") as HTMLInputElement;
      fileInput.click();
    };
  
    addFileHandler = (e: any) => {

      const file: any = e.target.files[0];
      
  
      if (file && file.size > 0) {
        var reader: any = new FileReader();
        reader.readAsDataURL(file);
  
        reader.onload = () => {
          if (reader.result.split(":")[1].split("/")[0] === "image") {
            this.setState({ image: reader.result, document:  file });
          }
        };
      }
    };
   onUpload =(e:any)=>{
    const formData = new FormData();
    
    // Update the formData object

    for (const property in this.state) {
    
      formData.append(
       property,
        this.state[property],
      
       
      );
    }
    
   
     this.props.UploadFile(formData);
    // axios.post( "http://localhost/IVY_ParentPortal/backend/guardian/upload.php", formData );
   }

    render() {
      
        return this.state.loading === false ? (
            <div >
          
              <div className="row justify-content-center align-items-center text-center">
                <h2
                  className="text-white"
                  style={{
                    textShadow:
                      "0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)",
                    fontSize: "2rem",
                  }}
                >
                  IVY-IS 
                </h2>
                
              </div>
              <div className="row">
                <div className="col-lg-4 mx-auto mt-1">
                  <div className="card shadow" style={{ backgroundColor: 'rgb(44, 55, 94)' }}>
                    <div className="card-body">
                      <div className="text-center my-6">
                        <p className="d-block h5 mb-0 text-white">Required Fees: 60000 <br />= Subscriptions amount  - Payments amount</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row my-3 justify-content-center align-items-center">
                <select
                  className="form-select col-sm-6"
                  aria-label="Default select"
                  onChange={(e) => this.setState({ paymentSelect: e.target.value })}
                >
                  <option value="">Choose Method Payment</option>
                  <option value="Cash at the school">Cash at the school</option>
                  <option value="Online payment">Online payment</option>
                  <option value="Bank transfer">Bank transfer</option>
                </select>
              </div>
              {this.state.paymentSelect === "Cash at the school" ? (
                <div className="row justify-content-center align-items-center">
                  <div className="col-sm-12 text-center text-white rounded m-2 p-4" style={{ backgroundColor: 'rgb(44, 55, 94)' }}>
                    <p
                    style={{
                      color: 'white',
                      textAlign: 'center',
                      textShadow:'0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)',
                      fontSize: '1.2rem',
                    }}>
                      Please go to school and pass by room number: 133 and pay 50000LE.<br/>For any inquires, feel free to call the Ivy-Transportation Department on 0123456789 <br/> or send an email to : 
                      <a className="text-white" href="mailto:transporation@ivyis.org"> transporation@ivyis.org</a>
                    </p>
                  </div>
                </div>
              ) : null}
              {this.state.paymentSelect === "Online payment" ? (
                  <div className="row justify-content-center align-content-between" >
                    <div className="col-12 row justify-content-center align-content-center mt-2">
                    <form
                        // onSubmit={this.startPaymentProcess}
                        className="row m-0 p-0" >
                        <div className="col-12 form-groupform-inline row m-0 p-0  justify-content-center">
                          <div className="col-md-6 m-0 p-0">
                            <label className="col-12">
                              {" "}
                              Amount to pay (EGP):
                            </label>
                            <input
                              className="form-control"
                            // //   onChange={this.updateamount}
                              name="amount"
                              type="number"
                              min="1"
                           />
                          </div>
                        </div>
                        <div className="col-12  form-group form-inline row m-0 p-0 pb-2 justify-content-center">
                          <button className="btn col-md-6 btn-warning text-white font-weight-bold mt-4">
                            Start Payment Process!
                          </button>
                        </div>
                      </form>
                    </div>
      
                    <div className="container d-flex justify-content-center align-items-center m-3">
                      {/* <div>
                        <img
                          src={ivy_logo}
                          height="100px"
                          className="rounded d-block "
                          style={{ width: "auto" }}
                          alt="..."
                        />
                      </div>
                      <div>
                        <img
                          src={harmony_logo}
                          height="40px"
                          className="rounded d-block"
                          style={{ width: "auto" }}
                          alt="..."
                        />
                      </div> */}
                    </div>
                  </div>
              ) : null}
              {this.state.paymentSelect === "Bank transfer" ? (
                <div className="row justify-content-center align-items-center text-white">
                  <input
                    type="file"
                    id="addFile"
                    hidden={true}
                    onChange={this.addFileHandler}
                  />
                 
                  <div className=" row col-12 justify-content-center text-center text-white rounded m-2 p-4" style={{ backgroundColor: 'rgb(44, 55, 94)' }}>
                    <p
                    style={{
                      color: 'white',
                      textAlign: 'center',
                      textShadow:'0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)',
                      fontSize: '1.2rem',
                    }}>
                      1-Go to your preferred bank.<br/>
                      2-You Should transfer: 50000LE to IVY-Transportation Account: <strong>100045914296</strong> .<br/> 
                      3-Take a clear image of the transfer receipt<br/> 
                      4-then attach the transfer receipt via the above "Upload Image" 
                    </p>
                  </div>
                  <div className="row justify-content-center  align-items-center">
                    <button
                      type="button"
                      disabled={this.props.loading}
                      style={{ border: "none", background: "inherit",fontSize: '1.4rem'}}
                      className={`row  justify-content-center align-items-center text-blue ${
                        this.props.loading ? "text-secondary" : "text-blue"
                      }`}
                      onClick={this.openAddFileInputHandler}
                    >
                      <i className="fas fa-plus-circle fa-2x"></i> Upload Image <div>&nbsp;</div>
                    </button>
                    {this.state.image ? (
                      <img src={this.state.image} alt=""></img>
                    ) : null}
                    {this.state.image ? (
                      <div className="row col-12 justify-content-center">
                      <button className="btn btn-warning text-white font-weight-bold my-3 pl-5 pr-5" onClick={this.onUpload}> 
                        Upload
                      </button> </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
              
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center mt-5">
              <Loader  height={100} width={100} />
            </div>
          );
    }

}

function mapStateToProps(state: any) {
  return {
      guardian: state.Parent.guardian,
     guardianLoading: state.Parent.guardianLoading,
   
  };
}

export default  connect(
      mapStateToProps, // Selects which state properties are merged into the component's props
      mapDispatchToProps // Selects which action creators are merged into the component's props
    )(DoPayment as any)