import React, { useEffect, useState } from 'react';
import MDBDataTableV5 from '../../CustomDataTable';
import { Spinner, Card, CardBody, CardHeader } from 'reactstrap';
import Swal from 'sweetalert2'
import axios from "axios";
import ExcuseModal from './ExcuseModal';
import { useSelector } from 'react-redux';
import { Translate } from '../../../helpers/Translate';

const ExcusesTables: React.FC = () => {
    const guardian = useSelector((state: any) => state.Parent.guardian);
    const [modal, setModal] = useState(false);
    const t = new Translate()

    const [intDataTable, setIntDataTable] = useState({
        columns: [
            {
                label: "#",
                field: "id"
            },
            {
                label: t.translate("Student ID"),
                field: "student_id"
            },
            {
                label: t.translate("Student Name"),
                field: "fullname"
            },
            {
                label: t.translate("Reason"),
                field: "reason"
            },
            {
                label: t.translate("Date"),
                field: "date"
            },

            {
                label: t.translate("Status"),
                field: "status"
            }
        ],
        rows: [
            {
                id: <Spinner color='info' />,
                student_id: <Spinner color='info' />,
                fullname: <Spinner color='info' />,
                reason: <Spinner color='info' />,
                date: <Spinner color='info' />,

                status: <></>
            }
        ]
    });


    const getExcuses = () => {
        try {
            axios.post(`${process.env.REACT_APP_BASEURL}student/excuse/getexcuses.php`, { guardian_id: guardian.guardian_id }).then(
                (res) => {

                    let excuses: any[] = [];
                    res.data.data.map((excuse: any) => {
                        let index = excuses.findIndex((ele: any) => ele.id == excuse.id && ele.student_id == excuse.student_id);
                        if (index !== -1) {
                            excuses[index].date = (<span>   {excuses[index].date} <br /> {excuse.date} </span>);
                        } else {
                            excuses.push(excuse)
                        }
                    })



                    setIntDataTable(prevState => {
                        const newState = { ...prevState };
                        let ser = 1;
                        // change .exams below to .homeworks after backend finished
                        let newRows = (excuses).map((i: any, key: any) => {

                            return {
                                ...i,
                                id: ser++,
                                student_id: i.student_id,
                                fullname: i.fullname,
                                reason: i.reason,
                                date: i.date,

                                status: (
                                    <button className={i.status == "Approved" ? "btn btn-success" : i.status == "Rejected" ? "btn btn-danger" : "btn btn-info"}>{i.status}</button>
                                )
                            }
                        });
                        return { ...newState, rows: newRows };
                    });
                }
            )
        } catch {
            Swal.fire('Error while grapping interventions!', '', 'error');
        }
    }

    useEffect(() => {
        getExcuses();
    }, [])

    return (
        <>

            <ExcuseModal modal={modal} setModal={() => { setModal(false) }} getExcuses={() => { getExcuses(); }} />

            <div className="row justify-content-center w-100 mx-0 my-5 linkssec">
                <Card>

                    <CardHeader>{t.translate("Excuses")}</CardHeader>
                    <hr />
                    <button className="btn btn-primary" style={{ maxWidth: "150px" }} onClick={() => {
                        setModal(true);
                    }}>
                        {t.translate("Request excuse")}
                    </button>
                    <CardBody className="bg-light">
                        <MDBDataTableV5 hover
                            responsive
                            borderless={false}
                            bordered
                            striped
                            entriesOptions={[5, 20, 25]}
                            entries={5} pagesAmount={4}
                            data={intDataTable}
                            fullPagination
                            searchTop
                            searchBottom={false}
                        />
                    </CardBody>
                </Card>
            </div>
        </>

    );
}


export default ExcusesTables