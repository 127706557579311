import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestWithAxios } from '../helpers/func';
import { url } from './initialStatus';
import * as Initial from './initialStatus';
// Initial state
const initialState = Initial.Chat;

// Async actions using createAsyncThunk

export const GetMessages:any = createAsyncThunk(
  'chat/getMessages',
  async (data, { rejectWithValue }) => {
    try {
      const res = await requestWithAxios(url + "users/chatlistusers/getmessages.php", data);
      return {
        messages: res.data.data.messages,
        image: res.data.data.image,
        name: res.data.data.name,
        count: res.data.data.count[0].count
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const SaveMsg:any = createAsyncThunk(
  'chat/saveMsg',
  async (data, { rejectWithValue }) => {
    try {
      const res = await requestWithAxios(url + "users/chatlistusers/savemessage.php", data);
      return res.data.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const ReadMsg:any = createAsyncThunk(
  'chat/readMsg',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const res = await requestWithAxios(url + "users/chatlistusers/readmsg.php", data);
      dispatch(GetUnReadMessages({ source: 50 }));
      return res.data.data;
      // No need to return anything
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const GetUsers:any = createAsyncThunk(
  'chat/getUsers',
  async (data, { rejectWithValue }) => {
    try {
      const res = await requestWithAxios(url + "users/chatlistusers/getusers.php", data);
      return res.data.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const GetUnReadMessages:any = createAsyncThunk(
  'chat/getUnReadMessages',
  async (data, { rejectWithValue }) => {
    console.log("GetUnReadMessages",data)
    try {
      const res = await requestWithAxios(url + "users/chatlistusers/getunreadmessage.php", data);
      if(res.data?.data) {
        return res.data.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// Create the slice
const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetMessages.fulfilled, (state, action) => {
        state.Messages = action.payload.messages;
        state.image = action.payload.image;
        state.name = action.payload.name;
        state.count = action.payload.count;
      })
      .addCase(GetUsers.fulfilled, (state, action) => {
        state.Users = action.payload;
      })
      .addCase(GetUnReadMessages.fulfilled, (state, action) => {
        state.UnReadMessages = action.payload;
      });
    // Add other cases for rejected states if needed
  }
});

export const actionCreators={
  GetMessages,
  SaveMsg,
  ReadMsg,
  GetUsers,
  GetUnReadMessages
}

export default chatSlice.reducer;