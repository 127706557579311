import React from 'react';
import { Table, Input, Row, Col, Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const CustomDataTable = ({
    hover,
    responsive,
    borderless = false,
    bordered,
    striped,
    entriesOptions = [10, 25, 50, 100],
    entries = 10,
    pagesAmount = 4,
    data,
    fullPagination = false,
    searchTop = true,
    searchBottom = false
}: { hover: boolean, responsive: boolean, borderless: boolean, bordered: boolean, striped: boolean, entriesOptions: Array<number>, entries: number, pagesAmount: number, data: any, fullPagination: boolean, searchTop: boolean, searchBottom: boolean }) => {
    const [rowsPerPage, setRowsPerPage] = React.useState(entries);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [searchText, setSearchText] = React.useState('');
    const [filteredData, setFilteredData] = React.useState(data.rows);

    React.useEffect(() => {
        if (data.rows.length <= 1) {
            setFilteredData(data.rows)
        } else {
            let dt = data.rows.filter((row: any) => {
                for (let i = 0; i < data.columns.length;i++){
                    let col = data.columns[i];
                    if (typeof (row[col.field]) == "string" && row[col.field].toLowerCase().includes(searchText.toLowerCase())) {
                        return true
                    }

                }

                return false
            })
            setFilteredData(dt);
        }
    }, [searchText, data.rows]);

    const handlePageChange = (pageIndex: number) => {
        setCurrentPage(pageIndex);
    };

    const handleRowsPerPageChange = (e: any) => {
        setRowsPerPage(Number(e.target.value));
        setCurrentPage(0);
    };

    const paginatedData = filteredData.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage);

    const totalPages = Math.ceil(filteredData.length / rowsPerPage);

    return (
        <div className={`${responsive ? 'table-responsive' : ''}`}>
            {searchTop && (
                <Row className="mb-3">
                    <Col>
                        <Input
                            type="text"
                            placeholder="Search"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </Col>
                </Row>
            )}
            <Table
                hover={hover}
                bordered={bordered}
                borderless={borderless}
                striped={striped}
                responsive={responsive}
            >
                <thead>
                    <tr>
                        {data.columns.map((column: any, idx: number) => (
                            <th key={idx}>{column.label}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {paginatedData.length > 0 ? (
                        paginatedData.map((row: any, idx: number) => (
                            <tr key={idx}>
                                {data.columns.map((column: any, colIdx: number) => (
                                    <td key={colIdx}>{row[column.field]}</td>
                                ))}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={data.columns.length}>No records found</td>
                        </tr>
                    )}
                </tbody>
            </Table>
            {fullPagination && totalPages > 1 && (
                <Pagination>
                    <PaginationItem disabled={currentPage === 0}>
                        <PaginationLink
                            first
                            onClick={() => handlePageChange(0)}
                        />
                    </PaginationItem>
                    <PaginationItem disabled={currentPage === 0}>
                        <PaginationLink
                            previous
                            onClick={() => handlePageChange(currentPage - 1)}
                        />
                    </PaginationItem>
                    {[...Array(totalPages)].map((page, idx) => (
                        <PaginationItem active={idx === currentPage} key={idx}>
                            <PaginationLink onClick={() => handlePageChange(idx)}>
                                {idx + 1}
                            </PaginationLink>
                        </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage === totalPages - 1}>
                        <PaginationLink
                            next
                            onClick={() => handlePageChange(currentPage + 1)}
                        />
                    </PaginationItem>
                    <PaginationItem disabled={currentPage === totalPages - 1}>
                        <PaginationLink
                            last
                            onClick={() => handlePageChange(totalPages - 1)}
                        />
                    </PaginationItem>
                </Pagination>
            )}
            <Row className="mt-3 mb-2">
                <Col md="2" className="d-flex align-items-center">
                    <Input
                        type="select"
                        value={rowsPerPage}
                        onChange={handleRowsPerPageChange}
                        className='form-control'
                    >
                        {entriesOptions.map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </Input>
                </Col>
            </Row>
            {searchBottom && (
                <Row className="mt-3 mb-2">
                    <Col>
                        <Input
                            type="text"
                            placeholder="Search"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default CustomDataTable;