import React, { useState, useEffect } from "react";
import { Hourglass } from "react-loader-spinner";
import ivy_logo from '../../assets/ivy_logo.png';
import harmony_logo from '../../assets/harmonylogo.svg';
import { actionCreators as ParentCreators } from "../../store/Parent";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import $ from 'jquery';
import Swal from "sweetalert2";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const jwt = useSelector((state:any) => state.Parent.jwt);
  const loginerror = useSelector((state:any) => state.Parent.loginerror);

  const onSubmit = (e:any) => {
    e.preventDefault();
    dispatch(ParentCreators.Login({ userName, password }));
  };

  const onCancel = () => {
    dispatch(ParentCreators.ClearParent());
    dispatch(ParentCreators.ClearJwt());
    navigate("/dashboard/viewprofile");
  };

  useEffect(() => {
    $("preloader").hide();
    // dispatch(ParentCreators.ClearJwt());
  }, []);

  useEffect(() => {
    if (jwt && jwt !== "") {
      navigate("/dashboard");
    }
  }, [jwt, navigate]);

  useEffect(() => {
    if (loginerror) {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: loginerror
      }).then(() => {
        onCancel();
      });
    }
  }, [loginerror]);

  return (
    <div className='container '>
      <div
        className='row align-items-center justify-content-center'
        style={{ height: '100vh' }}
      >
        {!loading ? (
          <div className='col-xl-10 col-lg-12 col-md-9'>
            <div className='card o-hidden border-0 shadow-lg '>
              <div className='card-body p-0'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <div className='p-5 bg-ivy-dark'>
                      <div className='col-12 row justify-content-center align-items-center align-content-center '>
                        <div className='col-12 row justify-content-center align-items-center mt-3'>
                          <img
                            src={ivy_logo}
                            height='150px'
                            width='150px'
                            className='rounded d-block '
                            style={{ width: 'auto' }}
                            alt='...'
                          />
                        </div>
                        <div className='col-12 row justify-content-center align-items-center mt-3'>
                          <img
                            src={harmony_logo}
                            height='40px'
                            className='rounded d-block'
                            style={{ width: 'auto' }}
                            alt='...'
                          />
                        </div>
                        <div className='col-12 mt-4  text-center'>
                          <h2
                            className='text-white'
                            style={{
                              textShadow:
                                '0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)',
                              fontSize: '1.2rem',
                            }}
                          >

                          </h2>
                        </div>
                      </div>
                      <form className='col-12' onSubmit={onSubmit}>
                        <div className='form-group row  justify-content-center mt-2'>
                          <div className='col-md-6 col-xl-5'>
                            <input
                              id='input_email'
                              value={userName}
                              onChange={(e) => setUserName(e.target.value)}
                              required={true}
                              maxLength={100}
                              type="email"
                              className='form-control form-control-lg border-0 text-black'
                              placeholder='Enter Email'
                            />
                          </div>
                        </div>
                        <div className='form-group row  justify-content-center my-2'>
                          <div className='col-md-6 col-xl-5'>
                            <input
                              id='input_password'
                              type='password'
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              required={true}
                              maxLength={100}
                              placeholder='Enter Password'
                              className='form-control form-control-lg border-0 text-black'
                            />
                          </div>
                        </div>
                        <div className='form-group row  justify-content-center mt-5'>
                          <div className='col-md-6 col-xl-5'>
                            <button
                              type='submit'
                              className={`btn btn-secondary btn-block btn-warning text-white`}
                            >
                              Login
                            </button>
                          </div>
                        </div>
                      </form>
                      <div className="text-white row justify-content-center">
                        <p style={{ display: "inline" }}>Forget your password ?</p>
                        <NavLink style={{ color: "#ffbd30" }} to={"/GenerateCode"}>
                          <div style={{ display: "inline" }}>&nbsp;</div> Reset password
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='d-flex justify-content-center align-items-center mt-5'>
            <Hourglass height={100} width={100} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;