import React, { useEffect, useState } from "react";
import MDBDataTableV5 from "../../CustomDataTable";
import { Spinner, Card, CardBody, CardHeader } from "reactstrap";
import Swal from "sweetalert2";
import axios from "axios";
import { Link } from "react-router-dom";
import { Translate } from "../../../helpers/Translate";

const Intervention: React.FC = () => {
  let t = new Translate();
  const [intDataTable, setIntDataTable] = useState({
    columns: [
      {
        label: "#",
        field: "id",
      },
      {
        label: t.translate("Student ID"),
        field: "stdid",
      },
      {
        label: t.translate("Student Name"),
        field: "stdname",
      },
      {
        label: t.translate("Offence"),
        field: "offence",
      },
      {
        label: t.translate("Teacher"),
        field: "teacher",
      },
      {
        label: t.translate("View"),
        field: "view",
      },
    ],
    rows: [
      {
        id: <Spinner color="info" />,
        stdid: <Spinner color="info" />,
        stdname: <Spinner color="info" />,
        btype: <Spinner color="info" />,
        teacher: <Spinner color="info" />,
        view: <></>,
      },
    ],
  });

  useEffect(() => {
    try {
      axios
        .get(`${process.env.REACT_APP_BASEURL}student/getinterventions.php`)
        .then((res) => {
          console.log(res.data.data);
          setIntDataTable((prevState) => {
            const newState = { ...prevState };
            let ser = 1;
            // change .exams below to .homeworks after backend finished
            let newRows = res.data.data.map((i: any, key: any) => {
              return {
                ...i,
                id: ser++,
                stdid: i.student_id,
                stdname: i.fullname,
                btype: i.inappropiatebehavior,
                teacher: i.teachername,
                view: (
                  <Link
                    to={`/dashboard/interventions/${i.id}`}
                    className="btn btn-info"
                  >
                    {t.translate("View")}
                  </Link>
                ),
              };
            });
            return { ...newState, rows: newRows };
          });
        });
    } catch {
      Swal.fire("Error while grapping interventions!", "", "error");
    }
  }, []);

  return (
    <>
      <div className="row justify-content-center w-100 mx-0 my-5 linkssec">
        <Card>
          <CardHeader>{t.translate("Interventions")}</CardHeader>
          <hr />
          <CardBody className="bg-light">
            <MDBDataTableV5
              hover
              responsive
              borderless={false}
              bordered
              striped
              entriesOptions={[5, 20, 25]}
              entries={5}
              pagesAmount={4}
              data={intDataTable}
              fullPagination
              searchTop
              searchBottom={false}
            />
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default Intervention;
