import { configureStore } from '@reduxjs/toolkit'
import User from "./User";
import Parent from "./Parent";
import Chat from "./Chat";
import Student from "./student";
import CCreator from "./ComponentCreator";
//import { InitialFeedback } from './forms';
import * as Initial from "./initialStatus";import {CLEAR_USER_LOGOUT } from './ActionTypes';

 const store = configureStore({
    // Automatically calls `combineReducers`
    reducer: {
        user: User,
        Parent: Parent,
        Chat: Chat,
        Student: Student,
        componentCreator: CCreator
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  })


export default store;
