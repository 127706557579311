import React, { Component } from "react";
import "./nav.css";

export default class Nav extends Component {
  render() {
    return (
      <div className="nav">

        <div className="nav__blocks"></div>
        <div className="nav__blocks"></div>
      </div>
    );
  }
}
